import React, { useState, useEffect } from 'react';
import comm from '../../common';
import axios from 'axios';
import { Button, Hidden, IconButton, Snackbar } from '@material-ui/core';
import Select from "react-select";
import { autoSendDays } from './selectItems';
import { userDto } from '../mobile/memberList';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { useHistory } from 'react-router-dom';
import { Receiver, SendSmsDto, memberType, selectDataType } from '../common/dto';
import { to2DArray } from '../common/util';
import { BasicBlueButton } from '../common/commonUI';


interface ChildProps {
    fire: () => void,
    club: number,
    usersToSend: Array<userDto>,
}

interface ISurveyInput {
    item:string;
    key:number;
}

const SendSMSTotal: React.FC<ChildProps> = (props: ChildProps) => {
    // console.log(" props => ", props);
    const history: any = useHistory();
    const [checkedMemberList, setCheckedMemberList] = useState<Array<memberType>>([]);
    
    const [clubOption, setClubOption] = useState<Array<selectDataType>>([]);
    const [jiguOption, setjiguOption] = useState<Array<selectDataType>>([]);
    const [jiyeokOption, setjiyeokOption] = useState<Array<selectDataType>>([]);

    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [searchAreaOpen, setSearchAreaOpen] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [searchResult, setSearchResult] = useState<Array<userDto>>();
    const [selectedClubPosition, setSelectedClubPosition] = useState({ value: 0, label: "클럽 직책을 선택해 주세요" });
    const [selectedJiyeokPosition, setSelectedJiyeokPosition] = useState({ value: 0, label: "지역 직책을 선택해 주세요" });
    const [selectedJiguPosition, setSelectedJiguPosition] = useState({ value: 0, label: "지구 직책을 선택해 주세요" });
    const [checkedAdmin, setCheckedAdmin] = useState(false);
    const [checkedAll, setCheckedAll] = useState(false);
    const [sendingType, setSendingType] = useState("general");
    
    const [selectedAutoSendDays, setSelectedAutoSendDays] = useState({ value: 0, label: "발송 주기 선택"});
    const [smsContent, setSmsContent] = useState("");

    const [selectionType, setSelectionType] = useState(0);
    
    const [surveyItems, setSurveyItems] = useState<Array<ISurveyInput>>([])

    const [singleReceiver, setSingleReceiver] = useState<Receiver>();
    const [receivers, setReceivers] = useState<Array<Receiver>>([]);
    const [finalReceivers, setFinalReceivers] = useState<Array<Array<Receiver>>>([]);

    const jiguId = history.location.state.jiguId;

    const [surveyItem1, setSurveyItem1] = useState("");
    const [surveyItem2, setSurveyItem2] = useState("");
    const [surveyItem3, setSurveyItem3] = useState("");
    const [surveyItem4, setSurveyItem4] = useState("");
    const [surveyItem5, setSurveyItem5] = useState("");

    useEffect(() => {
        if(history.location.state.usersToSend){
            let receivers : Array<Receiver> = history.location.state.usersToSend.map((each : userDto) => {
                return {name: each.name,
                    mobileNum: each.mobileNum, 
                    memberId:each.id,
                    belongTo:each.belongTo}
            });
            console.log("receivers >>>>> ", receivers);
            setReceivers(receivers);
        }
        // setReceivers([{name:'메메송', mobileNum:'01079993081', belongTo:5, memberId:1221}])
        axios.get(comm.SERVER_URL+"/position/jigu/"+jiguId).then(res => {
            setjiguOption(res.data);
        });
        axios.get(comm.SERVER_URL+"/position/club/"+jiguId).then(res => {
            setClubOption(res.data);  
        })
        axios.get(comm.SERVER_URL+"/position/jiyeok/"+jiguId).then(res => {
            setjiyeokOption(res.data);
        })


    }, []);

    const SendingSMSSnackBar = () => {
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    style={{ backgroundColor: '#000000' }}
                    open={snackBarOpen}
                    autoHideDuration={2000}
                    message="앱 설치 안내 문자를 전송 중입니다..."
                />
            </div>
        );
    };

    const checkSurveyItemExists = () => {
        // 최소 2 문항 이상 내용이 존재해야함 
        if(surveyItem1.length > 0 && surveyItem2.length > 0){
            return true;
        }else{
            alert('최소 2문항(1,2번) 이상 내용이 필요합니다.');
            return false;
        }
    }

    const sendSMS = async () => {
        // 먼저 SMS를 DB에 저장한 후, id를 따오고,
        // 링크로 회원 id와 SMS id를 URL에 붙여서 보낸다.
        if(receivers.length == 0){
            alert('수신자가 없습니다.');
            return;
        }

        console.log("sendSMS !!! ");
        const smsInfo : SendSmsDto = {
            content:smsContent,
            receivers: receivers,
            type:sendingType,
            selectionType:selectionType,
            jiguId:jiguId,
        }

        console.log("smsInfo >> ", smsInfo);
        const sendResult = await axios.post(comm.SERVER_URL+"/sms/all", smsInfo);
        console.log("sendResult >> ", sendResult.data);
        if(sendResult.data.ok){
            alert('문자 전송이 완료 되었습니다.');
            if(sendingType == "survey" && checkSurveyItemExists()){
                let surveyData = [];
                if(surveyItem1.length > 0){
                    surveyData.push({itemContent: surveyItem1, smsId: sendResult.data.id});
                }
                if(surveyItem2.length > 0){
                    surveyData.push({itemContent: surveyItem2, smsId: sendResult.data.id});
                } 
                if(surveyItem3.length > 0){
                    surveyData.push({itemContent: surveyItem3, smsId: sendResult.data.id});
                }
                if(surveyItem4.length > 0){
                    surveyData.push({itemContent: surveyItem4, smsId: sendResult.data.id});
                }
                if(surveyItem5.length > 0){
                    surveyData.push({itemContent: surveyItem5, smsId: sendResult.data.id});
                }
                let result = await axios.post(comm.SERVER_URL+"/sms/surveyItems", surveyData);
                if(result.data.ok){
                    console.log("surveyItems saved");    
                }   
            }
        }
    };

    const closeModal = () => {
        props.fire();
    }

    const seeHistory = () => {
        history.push("/smsHistory");
    }

    const doSearch = async () => {
        let searched = await axios.get(comm.SERVER_URL+"/member/searchViaMember/" + keyword +"/" +jiguId);
        console.log("searched >> ", searched.data);   
        setSearchResult(searched.data);
        setSearchAreaOpen(!searchAreaOpen);
    }

    const addDirectReceiver = () => {
        if(singleReceiver){
            setReceivers(receivers.concat([singleReceiver]))
        }
    }

    const removeNumber = (numberToDelete : string, nameToDelete:string | undefined) => {
        console.log("numberToDelete => ", numberToDelete);
        setReceivers(receivers.filter(each => {
            return (each.mobileNum != numberToDelete || each.name != nameToDelete) 
        }))
    }

    const fetchTypeReceivers = async (type:string, position:string, jiguId:number) => {
        console.log("type, >> ", type);
        console.log("position, >> ", position);
        let typeReceivers = await axios.get(comm.SERVER_URL+"/member/getPositionReceivers/"+type+"/"+position+"/"+jiguId);
        console.log("typeReceivers >> ", typeReceivers);
        // receivers.concat(typeReceivers.data);
        setReceivers(receivers.concat(typeReceivers.data));
    }

    const getAdminReceivers = async () => {
        let adminReceivers = await axios.get(comm.SERVER_URL+"/member/admin/"+jiguId);
        let finalAdmins = adminReceivers.data.map((eachAdmin : Receiver)=>{
            return {...eachAdmin, name: eachAdmin?.name?.replace("라이온스클럽","")}
        })
        setReceivers(receivers.concat(finalAdmins));
    }

    const getAllMemberReceivers = async () => {
        let allMemberReceivers = await axios.get(comm.SERVER_URL+"/member/allSms/"+jiguId);
        console.log(" allMemberReceivers >> ", allMemberReceivers.data)
        // let finalAdmins = allMemberReceivers.data.map((eachAdmin : Receiver)=>{
        //     return {...eachAdmin, name: eachAdmin?.name?.replace("라이온스클럽","")}
        // })
        setReceivers(allMemberReceivers.data);
    }
    
    useEffect(() => {
        let twoDarray = to2DArray(receivers, 5);
        console.log("twoDarray >> ", twoDarray);
        setFinalReceivers(twoDarray);
    },[receivers])

    useEffect(()=>{

        console.log("checkedAdmin >> ", checkedAdmin);
        if(checkedAdmin){
            getAdminReceivers()
        }else if(!history.location.state.usersToSend){
            console.log("checked reset !!");
            setReceivers([])
        }else{
            
        }
    },[checkedAdmin])

    useEffect(()=>{

        console.log("checkedAll >> ", checkedAll);
        if(checkedAll){
            getAllMemberReceivers()
        }else if(!history.location.state.usersToSend){
            console.log("checked reset !!");
            setReceivers([])
        }else{

        }
    },[checkedAll])

    const onSelectPosition = (ev: any, type: string) => {
        if (type == "positionClub") {
            setSelectedClubPosition(ev);
        } else if (type == "positionJiyeok") {
            setSelectedJiyeokPosition(ev)
        } else if (type == "positionJigu") {
            setSelectedJiguPosition(ev)
        }
        console.log(" ev >> ", ev);
        fetchTypeReceivers(type, ev.label, jiguId)
    }

    const resetReceivers = () => {
        console.log("checked reset !!");
        setReceivers([]);
        setSelectedClubPosition({ value: 0, label: "클럽 직책을 선택해 주세요" });
        setSelectedJiyeokPosition({ value: 0, label: "지역 직책을 선택해 주세요" });
        setSelectedJiguPosition({ value: 0, label: "지구 직책을 선택해 주세요" });
        setCheckedAdmin(false);
        setCheckedAll(false);
        setSendingType('general');
    }

    const checkReplySetting = (ev:any) => {

    }

    const handleCheckAdmin = (ev:any) => {
        setCheckedAdmin(!checkedAdmin)
    }

    const handleCheckAllMembers = (ev:any) => {
        setCheckedAll(!checkedAll)
    }


    const handleCheckMonthReport = (ev:any) => {
        if(sendingType == "monthlyReport"){
            setSendingType("general");
        }else{
            setSendingType("monthlyReport");
        }
    }

    const handleCheckSelectionType = (ev:any) => {
        console.log(" ev >>> ", ev.target.checked);
        if(ev.target.checked){
            setSelectionType(2);
        }else{
            setSelectionType(1);
        }
        // if(sendingType == "monthlyReport"){
        //     setSendingType("general");
        // }else{
        //     setSendingType("monthlyReport");
        // }
    }

    const handleCheckSurvey = (ev:any) => {
        if(sendingType == "survey"){   
            setSendingType("general");
        }else{
            setSendingType("survey");
            setSelectionType(1);
        }
    }

    const handleSingleReceiver = (ev:any) => {
        let aReceiver : Receiver = {
            mobileNum: ev.target.value
        }
        setSingleReceiver(aReceiver);    
    }

    const addSearchedReceiver = (searched: userDto) => {
        let receiver : Receiver = {name: searched.name, mobileNum:searched.mobileNum, memberId:searched.id, belongTo: searched.belongTo }
        setReceivers(receivers.concat([receiver]));
    };

    const inputSurveyItem = (ev:any, itemNo:number) => {
        if(itemNo == 1){
            setSurveyItem1(ev.target.value);
        }else if(itemNo == 2){
            setSurveyItem2(ev.target.value);
        }else if(itemNo == 3){
            setSurveyItem3(ev.target.value);
        }else if(itemNo == 4){
            setSurveyItem4(ev.target.value);
        }else if(itemNo == 5){
            setSurveyItem5(ev.target.value);
        }
    }

    return (
        <div className='grid place-items-center'>
            <div className='flex flex-col max-w-xl mt-8'>
                <div className='flex flex-col max-w-xl items-end'>
                    <button type='button' className={"rounded-md text-sm border-blue-400 border-solid border-2 bg-blue-500 w-32 h-8 mt-2 hover:border-blue-800 hover:text-black text-white mr-10"}
                        onClick={seeHistory}>발송 기록 보기</button>
                </div>
                <div className='flex flex-col items-center text-2xl font-bold'>
                    문자 발송
                </div>
                {/* <div className='flex flex-row-reverse mr-10'>
            <span className="text-sm italic"
                onClick={seeHistory}>발송된 문자에서 복사하기</span>
        </div> */}
                <div className='flex flex-col pl-4 mt-6'>
                    <div className='flex flex-row items-center'>
                        <span className='mr-4 font-bold'>보내는 사람:</span>
                        <label className='flex items-center'>
                            <input type='checkbox' defaultChecked={true} className='w-5 h-5 mr-2' />지구본부
                        </label>
                        <span className='ml-8 mr-2'>직접 입력</span>
                        <input type='text' className='input-p2 w-28 p-1.5' />
                    </div>
                    <div className='flex flex-col mt-3'>
                        <div className='flex flex-row items-center justify-between'>
                            <span className='mr-4 font-bold'>받는 사람</span>
                            <BasicBlueButton width='w-18' height='h-7' btnText='초기화' font='text-sm' handleClick={resetReceivers} />  
                        </div>
                        <span className='ml-1 mt-2'>[직책으로 선택]</span>
                        <div className='flex flex-row mt-2'>
                            <Select
                                value={selectedClubPosition}
                                className="ml-1 w-32 mr-1 text-sm"
                                name="inputVolunteer"
                                isSearchable={false}
                                onChange={(ev) => { console.log("ev >> ", ev); onSelectPosition(ev, "positionClub");}}
                                options={clubOption} />
                            <Select
                                value={selectedJiyeokPosition}
                                className="ml-1 w-32 mr-1 text-sm"
                                name="inputVolunteer"
                                isSearchable={false}
                                onChange={(ev) => { onSelectPosition(ev, "positionJiyeok") }}
                                options={jiyeokOption} />
                            <Select
                                value={selectedJiguPosition}
                                className="ml-1 w-32 mr-1 text-sm"
                                name="inputVolunteer"
                                isSearchable={false}
                                onChange={(ev) => { onSelectPosition(ev, "positionJigu") }}
                                options={jiguOption} />
                            <label className='flex items-center ml-2 text-sm'>
                                <input type='checkbox' checked={checkedAdmin}  className='w-5 h-5 mr-1 text-xs' onChange={handleCheckAdmin} />관리자
                            </label>
                           
                            <label className='flex items-center ml-2 text-sm'>
                                <input type='checkbox' checked={checkedAll} className='w-5 h-5 mr-1 text-xs' onChange={handleCheckAllMembers} />전체회원
                            </label>
                        
                        </div>
                        <span className='ml-1 mt-2'>[검색 및 직접 입력]</span>
                        <div className='flex flex-row mt-2 items-center'>
                            <span className='mr-2'>검색: </span><input type='text' placeholder='회원명' className='input p-1.5 w-36' onChange={(ev) => setKeyword(ev.target.value)} />
                            <button type='button' className={"rounded-md text-sm border-blue-400 border-solid border-2 bg-blue-500 w-12 h-8 hover:border-blue-800 hover:text-black text-white ml-2"}
                                onClick={doSearch}>검색</button>
                            <span className='mr-2 ml-4'>직접 입력: </span><input type='number' placeholder='핸드폰번호 숫자만 입력' className='input p-1.5 w-36' onChange={handleSingleReceiver} />
                            <button type='button' className={"rounded-md text-sm border-blue-400 border-solid border-2 bg-blue-500 w-12 h-8 hover:border-blue-800 hover:text-black text-white ml-2"}
                                onClick={addDirectReceiver}>추가</button>
                        </div>
                        <div id='searchResult' className={searchAreaOpen ? 'w-54 mt-2 border-2 border-gray-200 p-2' : 'hidden'}>
                            {
                                searchResult?.map((each:userDto)=>{
                                    return <div className='flex flex-row items-center justify-center border border-gray-500 h-8 w-48 rounded-lg mt-1 ml-1' onClick={(ev) => {addSearchedReceiver(each)}}>{each.name} / {each.mobileNum}</div>
                                })
                            }
                        </div>
                        <div className='h-5'></div>
                        <div className={receivers.length > 0 ? 'text-base mb-2' : 'hidden'}>수신자</div>
                        <div className={receivers.length > 0 ? 'border-2 border-gray-500 rounded-lg pb-2 pt-2 pl-1 pm-1 w-160': ''}>
                        {
                            finalReceivers?.map((oneDarray,index) => {
                                return (<div className='flex flex-row items-center'>
                                    {
                                        oneDarray.map((eachRowItem:Receiver) => {
                                            console.log(" eachRowItem >> ", eachRowItem);
                                           return (<div className='flex flex-row items-center justify-between border border-gray-500 h-7 w-28 rounded-lg mt-1 ml-1'>
                                                    <div className='flex flex-row items-center justify-center w-24'>
                                                        <span className={eachRowItem.name ? 'text-xs ml-0.5' : 'hidden'}>{eachRowItem.name}</span><span className={eachRowItem.mobileNum ? 'text-xs ml-0.5' : 'hidden'}>{eachRowItem.mobileNum.substring(7,11)}</span>
                                                    </div>
                                                    <div className='ml-1'><HighlightOffOutlinedIcon onClick={(ev) => removeNumber(eachRowItem.mobileNum, eachRowItem?.name)} /></div>
                                           </div>) 
                                        })
                                    }
                                </div>)
                            })
                        }
                        </div>
                        <div className='flex flex-row items-center mt-4'>
                            <div className='font-bold'>전송 업무</div>
                            <span className='ml-4 text-sm mt-1 text-red-400'>*주의: 전송 업무를 선택하신 경우, 직접입력으로 입력된 번호로는 전송되지 않습니다</span>
                        </div>
            
                        <div className='flex flex-row mt-2'>
                            <label className='flex items-center'>
                                <input type='checkbox' defaultChecked={false} className='w-5 h-5 mr-1 text-sm' onChange={handleCheckMonthReport} />월간보고
                            </label>
                            <label className='flex items-center ml-5'>
                                <input type='checkbox' defaultChecked={false} className='w-5 h-5 mr-1 text-sm' onChange={handleCheckSurvey} />설문/투표
                            </label>
                        </div>
                        <span className='font-bold mt-5'>내용</span>
                            <textarea className='h-52 mt-2 border p-2 border-gray-400' onChange={(ev) => {setSmsContent(ev.target.value)}} />
                        {/* <div className='flex flex-row items-center mt-4'>
                            <span className='font-bold mr-4'>링크: </span>
                            <input type='text' className='input p-1.5 w-84  border-gray-400' />
                        </div> */}
                        <div className={sendingType == "survey" ? "" : "hidden"}>
                        <div className='mt-4 font-bold'>설문/투표</div>
                        <div className='flex flex-col border border-gray-600 rounded-md p-3 mt-2'>
                            <div className='flex flex-row justify-between'>
                                <span className=''>응답 보기 설정</span>
                                <label className='flex items-center mr-5'>
                                    <input type='checkbox'  className='w-5 h-5 mr-2 text-sm' onChange={handleCheckSelectionType} />중복 허용
                                </label>
                            </div>
                            <div className='flex flex-row items-center mt-2 ml-2'>
                                <span className=''>1.</span>
                                <input type='text' className='input p-1.5 ml-2 w-68' onChange={(ev) => inputSurveyItem(ev,1)} />
                            </div>
                            <div className='flex flex-row items-center mt-2 ml-2'>
                                <span className=''>2.</span>
                                <input type='text' className='input p-1.5 ml-2 w-68' onChange={(ev) => inputSurveyItem(ev,2)} />
                            </div>
                            <div className='flex flex-row items-center mt-2 ml-2'>
                                <span className=''>3.</span>
                                <input type='text' className='input p-1.5 ml-2 w-68' onChange={(ev) => inputSurveyItem(ev,3)}/>
                            </div>
                            <div className='flex flex-row items-center mt-2 ml-2'>
                                <span className=''>4.</span>
                                <input type='text' className='input p-1.5 ml-2 w-68' onChange={(ev) => inputSurveyItem(ev,4)}/>
                            </div>
                            <div className='flex flex-row items-center mt-2 mb-2 ml-2'>
                                <span className=''>5.</span>
                                <input type='text' className='input p-1.5 ml-2 w-68' onChange={(ev) => inputSurveyItem(ev,5)}/>
                            </div>
                        </div>

                        </div>
                        
                        {/* <div className='flex flex-col border border-gray-600 rounded-md p-3 mt-4'>
                                <div className='flex flex-row items-center'>
                                    <span className=''>자동발송 주기 설정</span>
                                    <Select
                                        value={selectedAutoSendDays}
                                        className="ml-3 w-28"
                                        name="inputVolunteer"
                                        isSearchable={false}
                                        onChange={(ev) => { if(ev)setSelectedAutoSendDays(ev);}}
                                        options={autoSendDays} />
                                </div>
                                <div className='flex flex-row justify-between items-center'>
                                    <span className='mt-2'>자동 발송 내용</span>
                                    <div className='flex flex-row mr-8'>
                                        <input type='checkbox' className='w-5 h-5 mr-2' />
                                        <span className='text-sm'>동일내용</span>
                                    </div>
                                </div>
                                <textarea className='w-132 h-24 border p-2 border-gray-400' />
                        </div> */}
                    </div>
                </div>
                <div className='max-w-xl mt-6 mb-4 flex items-center justify-center'>
                    <button type='button' className={"mt-4 mb-6 rounded-md text-sm border-blue-400 border-solid border-2 bg-blue-500 w-64 h-12 mt-2 hover:border-blue-800 hover:text-black text-white mr-10 text-lg"}
                        onClick={sendSMS}>문자 발송 하기</button>
                </div>
            </div>
        </div>
    )
}

export default SendSMSTotal;

