import React, { useState, useRef, useEffect } from "react";
import logo from "../../images/lions_logo.png";
import Button from "@material-ui/core/Button";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import PersonAdd from "@material-ui/icons/PersonAdd";
import DropoutMember from "@material-ui/icons/PersonRounded"
import AccountBoxIcon from "@material-ui/icons/AccountBox";
// import LogoutIcon from '@material-ui/icons/Logout';
import Description from "@material-ui/icons/Description";
import AddAlert from "@material-ui/icons/AddAlert";
import Send from "@material-ui/icons/Send";
import axios from "axios";
import comm from "../../common";
import { useHistory } from "react-router-dom";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import DoNotDisturbAltIcon from "@material-ui/icons/PinDropOutlined";
import PersonPinCircleTwoTone from "@material-ui/icons/StopOutlined";

import CloseIcon from "@material-ui/icons/Close";
import AddMember from "./addMember";
import AddClubInfo from "./addClubInfo";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import AddNotice from "./addNotice";

import { useCookies } from "react-cookie";
import SendAppInstallSMS from "./sendAppInstallSMS";
import ManageAdmin from "./manageAdmin";
import { BasicModal, getMemberImg, getMemberImgSimple, getMemberImgWithOption, getMemberImgWithOption2, useStyles } from "../common/util";
import AddMember356E from "./addMember356E";
import FileDownloadIcon from '@material-ui/icons/CloudDownload';
import saveAs from "file-saver";
// import { userDto } from '../mobile/memberList';

var encodeUrl = require('encodeurl')
interface userDto {
  id: number;
  name: string;
  positionClub: string;
  positionFreeClub: string;
  startDay: string;
  job: string;
  address: string;
  mobileNum: string;
  phoneNumHome: string;
  phoneNumWork: string;
  email: string;
  belongTo: number;
  belongToJiyeok:number;
  belongToJidae:number;
  imgName: string;

  belongToJigu:number;

  englishName?: string;
  memberNo?: string;
  positionJiyeok?: string;
  positionFreeJiyeok?: string;
  positionJigu?: string;
  positionFreeJigu?: string;
  sponId: any;
  supportCnt: number;
  formerJiguChongjae?: Array<number>;
  
  memberTypeArr:Array<string>;
  gender:string;
  sponsorName:string;
  birthYear:string;
  quitReason?:string;
  dropout?:string;
  zipCode:string;
  approvalDay:string;
  authLevel:number;
}

interface searchUserDto extends userDto {
  address: string;
  clubName: string;
  imgName: string;
  jidaeName: string;
  jiyeokName: string;
  jiguName: string;
  job: string;
  mobileNum: string;
  name: string;
  positionClub: string;
  positionJigu: string;
  positionJiyeok: string;
  sponsorName: string;
  belongToJigu:number;
  belongToJiyeok:number;
  belongToJidae:number;
  belongTo:number;
  gender:string;
  dropout:string;

}

interface clubDto {
  id: number;
  name: string;
  jidaeName: string;
  jiyeokName: string;
  jiguName: string;
  startDay: string;
  jiguNo: string;
  gukjeNo: string;
  sponsorClubName: string;
  address: string;
  tel: string;
  fax: string;
  homepage: string;
  email: string;
  slogan: string;
  clubOnly: string;
}

interface addClubDto{
  name:string;
  belongTo:number;
  jiyeokId:number;
  jiguId:number;
}

export interface belongToInfo {
  jidae?: number;
  jiyeok?: number;
  jigu?: number;
}

interface nameNId{
  name:string;
  id:number;
}

function AdminMain(props: any): any {
  const history: any = useHistory();
  const [jigu, setJigu] = useState(0);
  const [jiyeok, setJiyeok] = useState(0);
  const [jidae, setJidae] = useState(0);
  const [belonging, setBelonging] = useState<belongToInfo>({});
  const [club, setClub] = useState(0);

  const [clubInputName, setClubInputName] = useState("");

  const [clubName, setClubName] = useState("");
  const [showClubORMember, setShowClubORMember] = useState("member");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [jiguList, setjiguList] = useState([
    { id: 0, name: "지구를 입력하세요" },
  ]);
  const [jiyeokList, setjiyeokList]: any[] = useState<any[]>([]);
  const [jidaeList, setjidaeList] = useState([]);
  const [clubList, setClubList] = useState<Array<{name:string, id:number}>>([]);
  const [userList, setUserList] = useState([]);
  const [dropoutMemberList, setDropoutMemberList] = useState<Array<any>>([]);
  const [searchUserList, setSearchUserList] = useState<Array<searchUserDto>>(
    []
  );
  const [searchClubList, setSearchClubrList] = useState<Array<clubDto>>([]);

  const [deleteMemberSnackBarOpen, setDeleteMemberSnackBarOpen] =
    useState(false);
  const [logoutSnackBarOpen, setLogoutSnackBarOpen] = useState(false);
  const [addMemberModalOpen, setAddMemberModalOpen] = useState(false);
  const [addClubInfoModalOpen, setAddClubInfoModalOpen] = useState(false);
  const [addNoticeModalOpen, setAddNoticeModalOpen] = useState(false);
  
  const [addClubModalOpen, setAddClubModalOpen] = useState(false);
  
  const [sendSmsModalOpen, setSendSmsModalOpen] = useState(false);  
  // const [smsModalOpen, setSmsModalOpen] = useState(false);

  const [manageAdminModalOpen, setManageAdminModalOpen] = useState(false);

  const [deleteInfo, setDeleteInfo] = useState({ type: "", id: 0 });
  const [member, setMember] = useState(-1);
  // const [isSuperUser, setIsSuperUser] = useState(false);
  const [authLevel, setAuthLevel] = useState(0);

  // const [memberCnt, setMemberCnt] = useState(0);

  const [cookies, setCookie, removeCookie] = useCookies(["loginId"]);
  const [locationKeys, setLocationKeys]: Array<any> = useState([]);

  useEffect(() => {
    if (!history.location.state) {
      console.log("history >>>@@@@ ", history);
      history.push("/admin"); // 수정 -> adminMan 로그인 정보 없을시
      // history.goBack();
      return;
    }

    setCookie("loginId", history.location.state.id, {
      path: "/",
      maxAge: 3600,
    });

    let clubVal = history.location.state.club;
    let jidaeVal = history.location.state.jidae;
    let jiyeokVal = history.location.state.jiyeok;
    let jiguVal = history.location.state.jigu;

    axios.get(comm.SERVER_URL + "/group/jigu").then((res) => {
      console.log("jiguRes => ", res.data);
      console.log("ijguVal => ", jiguVal);
      if(!jiguVal){
        setjiguList(res.data);
      }else{
        setjiguList(res.data.filter((jigu:any) =>{
          return jigu.id == jiguVal;
        }));
      } 
      handleJigu(jiguVal);
      handleJiyeok(jiyeokVal);
      handleJidae(jidaeVal);
      console.log(" clubVal >>>> ", clubVal);
      handleClub(clubVal);
    });

    console.log(" history.location.state.id > ", history.location.state.id);
    axios
      .get(comm.SERVER_URL + "/admin/info", {
        params: {
          id: history.location.state.id,
        },
      })
      .then((res) => {
        // console.log("admin Info => ", res.data.admin);
        // setIsSuperUser(res.data.admin?.isSuperAdmin);
        setAuthLevel(res.data.admin?.authLevel);
      });
  }, []);

  useEffect(() => {
    history.listen((location?: any, action?: any) => {
      // console.log(" Main !!");
      // console.log(" location >> ", location);
      // console.log(" action >> ", action);
    });
  }, [history]);

  const handleJiguChange = (ev: any) => {
    handleJigu(ev.target.value);
  };

  const sendSMSByBBurio = () => {
    const bburioURLdev = "https://dev-api.bizppurio.com";
    const bburioURL = "https://api.bizppurio.com";
    const authPath = "/v1/token";
    const sendMsgPath = "/v3/message";

    console.log(" ppurio !! ");

    axios({
      method: "post",
      url: bburioURLdev + authPath,
      // data: { id: 'miiin', message: 'hi' },
      headers: {
        Authorization: "bGlvbnM6U2hzYnN5NzBA",
        "Content-type": "application/json; charset=utf-8",
      },
    })
      .then((res) => {
        // console.log(" res >> ", res);
      })
      .catch((exception) => {
        console.log(" exception >> ", exception);
      });
  };

  const handleJigu = (selected: number) => {
    let selectedJigu = selected;
    console.log(" selectedJigu > ", selected);
    setJigu(selected);
    setjiyeokList([]);
    setjidaeList([]);
    setClubList([]);
    setUserList([]);
    axios
      .get(comm.SERVER_URL + "/group/jiyeok", {
        params: {
          id: selected,
        },
      })
      .then((res) => {
        // console.log(" res jiyeoks => ", res.data);
        let jiyeoks = res.data;
        setjiyeokList(jiyeoks);
      });
  };

  const handleJiyeokChange = (ev: any) => {
    handleJiyeok(ev.target.value);
  };                      

  const handleJiyeok = (selected: number) => {
    setJiyeok(selected);
    setjidaeList([]);
    setClubList([]);
    setUserList([]);
    axios
      .get(comm.SERVER_URL + "/group/jidae", {
        params: {
          id: selected,
        },
      })
      .then((res) => {
        // console.log(" res jidaes => ", res.data);
        setjidaeList(res.data);
      });
  };

  const changeJiyeok = (ev:any) => {
    console.log(" changeJiyeok >> ", ev.target.value);
    console.log(" changeJiyeok >> ", ev.target.label);
    
  }
  
  const changeJidae = (ev:any) => {
    console.log(" changeJidae >> ", ev.target.value);
    console.log(" changeJidae >> ", ev.target.label);
  }


  const handleJidaeChange = (ev: any) => {
    handleJidae(ev.target.value);
  };

  const handleJidae = (selected: number) => {
    setJidae(selected);
    let selectedJidae = selected;
    setClubList([]);
    setUserList([]);
    axios
      .get(comm.SERVER_URL + "/group/club", {
        params: {
          id: selectedJidae,
        },
      })
      .then((res) => {
        // console.log(" res clubs => ", res.data);
        setClubList(res.data);
      });
  };

  const getUserList = (clubId: number) => {
    axios
      .get(comm.SERVER_URL + "/member/club", {
        params: {
          id: clubId,
        },
      })
      .then((res) => {
        // console.log(" getUserList res >> ", res.data);
        setUserList(res.data);
      });
  };

  const handleClubChange = (ev: any) => {
    handleClub(ev.target.value);
  };

  const handleClub = (selected: number) => {
    console.log(" selected club >>> ", selected);
    setShowClubORMember("member");
    setClub(selected);
    axios
      .get(comm.SERVER_URL + "/group/clubInfo/", {
        params: { id: selected },
      })
      .then((res) => {
        console.log(" res >> ", res.data);
        let resultData = res.data;
        setClubName(resultData?.name);
      });

    // Fetch Data of designated club members
    getUserList(selected);
  };



  const handleDetailSearch = (member: userDto) => {
    console.log(" id >>>>> ", member);
    handleJigu(member.belongToJigu);
    handleJiyeok(member.belongToJiyeok);
    handleJidae(member.belongToJidae);
    handleClub(member.belongTo);
    setMember(member.id);
    setAddMemberModalOpen(true);
  };

  const handleDetail = (id: number ) => {
    console.log(" id >>>>> ", member);
    setMember(id);
    setAddMemberModalOpen(true);
  };


  const DeleteWarningSnackBar = () => {
    const handleDeleteMember = async () => {
      console.log(" ddddddddddddd ");
      if (deleteInfo.type == "delete") {
        await axios.delete(comm.SERVER_URL + "/member", {
          params: { id: deleteInfo.id },
        });
      } else {
        await axios.put(comm.SERVER_URL + "/member/dropout/" + deleteInfo.id);
      }
      setDeleteMemberSnackBarOpen(false);
      getUserList(club);
    };

    const handleClose = () => {
      setDeleteMemberSnackBarOpen(false);
    };

    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          style={{ backgroundColor: "#000000" }}
          open={deleteMemberSnackBarOpen}
          //   autoHideDuration={6000}
          onClose={handleClose}
          message={
            deleteInfo.type === "delete"
              ? "삭제 진행 버튼을 클릭하시면 해당 회원이 삭제됩니다. 정말로 삭제 하시겠습니까?"
              : "탈퇴 진행 버튼을 클릭하시면 해당 회원이 탍퇴 처리 됩니다. 진행 하시겠습니까?"
          }
          action={
            <div className="flex flex-row justify-start items-center">
              <Button
                color="secondary"
                variant="contained"
                size="medium"
                onClick={handleDeleteMember}
              >
                {deleteInfo.type === "delete" ? "삭제 진행" : "탈퇴 진행"}
              </Button>
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </div>
          }
        />
      </div>
    );
  };

  const LogoutWarningSnackBar = () => {
    const handleLogout = () => {
      // history.location.state.club = null;
      // clubVal = null;
      setLogoutSnackBarOpen(false);
      history.goBack();
      history.replace();
    };

    const handleClose = () => {
      setLogoutSnackBarOpen(false);
    };

    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          style={{ backgroundColor: "#000000" }}
          open={logoutSnackBarOpen}
          //   autoHideDuration={6000}
          onClose={handleClose}
          message="로그아웃 하시겠습니끼?"
          action={
            <div className="flex flex-row justify-start items-center">
              <Button
                color="secondary"
                variant="contained"
                size="medium"
                onClick={handleLogout}
              >
                로그아웃
              </Button>
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </div>
          }
        />
      </div>
    );
  };
  
  const getAddMemberModalType = (jiguId:number) => {
    if(jiguId == 2){
      return <AddMember
      fire={() => {
        setAddMemberModalOpen(false);
        getUserList(club);
      }}
      club={club}
      clubName={clubName}
      memberId={member}
      extraData={belonging}
      jiyeok={jiyeok}
    /> 
    }else if(jiguId == 3){
      return <AddMember356E
      fire={() => {
        setAddMemberModalOpen(false);
        getUserList(club);
      }}
      club={club}
      clubName={clubName}
      memberId={member}
      extraData={belonging}
      jiyeok={jiyeok}
    /> 
    }
  }

  const AddMemberTransitionsModal = () => {
    const classes = useStyles();
    // const [openModal, setOpenModal] = useState(false);

    const handleClose = (ev: any, reason: string) => {
      if (reason == "backdropClick") {
        console.log(" reason >> ", reason);
        setAddMemberModalOpen(false);
      } else {
        setAddMemberModalOpen(false);
      }
    };
    // console.log(" clubName #### ", clubName)

    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.paper}
          open={addMemberModalOpen}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          // hideBackdrop={true}
        >
          <Fade in={addMemberModalOpen}>
            <div>
              {getAddMemberModalType(jigu)}
            </div>
          </Fade>
        </Modal>
      </div>
    );
  };

  const AddClubInfoTransitionsModal = () => {
    const classes = useStyles();
    // const [openModal, setOpenModal] = useState(false);

    const handleClose = () => {
      setAddClubInfoModalOpen(false);
    };

    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={addClubInfoModalOpen}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={addClubInfoModalOpen}>
            <div>
              <AddClubInfo
                fire={() => {
                  setAddClubInfoModalOpen(false);
                }}
                club={club}
                belonging={{
                  belongTo: belonging.jidae,
                  jiguId: belonging.jigu,
                  jiyeokId: belonging.jiyeok,
                }}
              />
            </div>
          </Fade>
        </Modal>
      </div>
    );
  };

  const AddNoticeTransitionsModal = () => {
    const classes = useStyles();
    // const [openModal, setOpenModal] = useState(false);

    const handleClose = () => {
      setAddNoticeModalOpen(false);
    };

    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={addNoticeModalOpen}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={addNoticeModalOpen}>
            <div>
              <AddNotice
                fire={() => {
                  setAddNoticeModalOpen(false);
                }}
                club={club}
              />
            </div>
          </Fade>
        </Modal>
      </div>
    );
  };

  const SendSmsTransitionsModal = () => {
    const classes = useStyles();
    // const [openModal, setOpenModal] = useState(false);
    const handleClose = () => {
      setSendSmsModalOpen(false);
    };

    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={sendSmsModalOpen}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={sendSmsModalOpen}>
            <div>
              <SendAppInstallSMS
                fire={() => {
                  setSendSmsModalOpen(false);
                }}
                club={club}
              />
            </div>
          </Fade>
        </Modal>
      </div>
    );
  };


  const ManageAdminTransitionModal = () => {
    const classes = useStyles();
    // const [openModal, setOpenModal] = useState(false);
    const handleClose = () => {
      setManageAdminModalOpen(false);
    };

    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={manageAdminModalOpen}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={manageAdminModalOpen}>
            <div>
              <ManageAdmin
                fire={() => {
                  setManageAdminModalOpen(false);
                }}
                club={club}
                jigu={jigu}
              />
            </div>
          </Fade>
        </Modal>
      </div>
    );
  };

  const getPositionIn = (
    positionIn: string | undefined,
    positionInFree: string | undefined,
    type: string
  ) => {
    if (positionIn) {
      if (positionIn !== "없음") {
        return type + ":" + positionIn;
      } else {
        return "";
      }
    } else if (positionInFree) {
      if (positionInFree !== "없음") {
        return type + ":" + positionInFree;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const getPositionInJigu = (
    positionIn: string | undefined,
    positionInFree: string | undefined,
    jiguChongjae: Array<number> | undefined,
    type: string
  ) => {
    let formerChongjae = "";
    if (jiguChongjae && jiguChongjae.length > 0) {
      formerChongjae = "역대 지구총장";
    }

    if (positionIn) {
      if (positionIn !== "없음") {
        if (formerChongjae) {
          return type + ":" + positionIn + " / " + formerChongjae;
        } else {
          return type + ":" + positionIn;
        }
      } else {
        return "";
      }
    } else if (positionInFree) {
      if (positionInFree !== "없음") {
        if (formerChongjae) {
          return type + ":" + positionIn + " / " + formerChongjae;
        } else {
          return type + ":" + positionIn;
        }
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const getListItem = (listData: Array<userDto>) => {
    if (listData.length === 0) {
      return (
        <div className="flex flex-col justify-center items-center mt-8">
          <p className="text-2xl">회원이 없습니다.</p>
        </div>
      );
    } else {
      return listData.map((each, idx) => {
        // console.log(" each >> ", each);
        let id = each.id;
        let position = each.positionClub
          ? each.positionClub
          : each.positionFreeClub;
        if(!position){
          position = "미입력";
        }
        let englishName = each.englishName ? each.englishName : "";
        let memberNo = each.memberNo ? " / 회원번호: " + each.memberNo : "";
        let sponsor = each.sponId ? "협찬: " + each.sponId.title : "";
        let support = each.supportCnt ? "찬조: " + each.supportCnt + "건" : "";
        let nameNPosition = each.name + " / " + position
        let startDay = each.startDay;
        let job = each.job ? each.job : "";
        let address = each.address;
        let mobileNum = each.mobileNum;
        // let authLevel = each.authLevel;
        if(each.mobileNum){
          mobileNum = "H.P) " + mobileNum.substr(0, 3) +"-" +mobileNum.substr(3, 4) +"-" +mobileNum.substr(7, 4);
        }else{
          mobileNum = "";
        }
        
        let imgUrl = getMemberImgSimple(each.belongToJigu, each.imgName, each.gender);
        // let imgUrl = getMemberImg(each);
        
        let email = each.email ? each.email : "";
        let phoneNumHome = each.phoneNumHome ? "집: " + each.phoneNumHome : "";
        let phoneNumWork = each.phoneNumWork
          ? "직장: " + each.phoneNumWork
          : "";

        return (
          <div>
            {idx === 0 ? (
              <div className="h-0.2 bg-gray-300 w-full mt-3 lg:mr-3 ml-3"></div>
            ) : null}
            <div className="flex flex-row items-center w-full mt-5">
              <div>
                <img src={imgUrl} alt="" className="w-20 h-20 ml-2 lg:ml-5 mr-2 lg:mr-7" />
              </div>
              <div
                className="flex flex-col w-66 lg:w-124"
                onClick={(ev: any) => handleDetail(id)}
              >
                <div className="h-5 w-66">
                  <span>{nameNPosition}</span>
                </div>
                <div className="bg-gray-700 w-60"></div>
                <div className="h-30 bg-blue-100 mt-3 p-3 rounded-xl w-66 lg:w-60 lg:w-124 ">
                  <p>{englishName}</p>
                  <p>
                    {startDay} 입회 {memberNo}
                  </p>
                  <p>
                    {getPositionIn(
                      each.positionJigu,
                      each.positionFreeJigu,
                      "지구"
                    )}{" "}
                    {getPositionIn(
                      each.positionJiyeok,
                      each.positionFreeJiyeok,
                      "지역"
                    )
                      ? "  " +
                        getPositionIn(
                          each.positionJiyeok,
                          each.positionFreeJiyeok,
                          "지역"
                        )
                      : ""}
                  </p>
                  <p>
                    <span>{sponsor}</span>
                    <span className={sponsor ? "ml-3" : "ml-0"}>{support}</span>
                  </p>
                  <p>{job}</p>
                  <p>{address}</p>
                  <p>{email}</p>
                  <p>
                    <span>{mobileNum}</span>
                    <span className="ml-3">{phoneNumHome}</span>
                    <span className={phoneNumHome ? "ml-3" : "ml-0"}>
                      {phoneNumWork}
                    </span>{" "}
                  </p>
                </div>
                <div className={authLevel > 1 ? "flex flex-row mt-2 ml-2 lg:hidden" : "hidden"}>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<DeleteIcon />}
                    onClick={(ev) => {
                      console.log(" id =>> ", id);
                      deleteMember("delete", id);
                    }}
                  >
                    삭제
                  </Button>
                  <div className="w-3" />
                  <div className={authLevel > 1 ? "" : "hidden"}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    className="hidden"
                    // startIcon={<PersonPinCircleTwoTone />}
                    onClick={(ev) => {
                      console.log(" id =>> ", id);
                      deleteMember("dropout", id);
                    }}
                  >
                    회원 탈퇴
                  </Button>
                  </div>
                </div>
              </div>
              <div className={authLevel > 1 ? "mt-2 ml-2 lg:ml-4 lg:block" : "hidden"}>
                <div className="h-5" />
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={(ev) => {
                    console.log(" id =>> ", id);
                    deleteMember("delete", id);
                  }}
                >
                  삭제
                </Button>
                <div className="h-3" />
                <div className={authLevel > 1 ? "" : "hidden"}>
                <Button
                  variant="outlined"
                  color="secondary"
                  // startIcon={<PersonPinCircleTwoTone />}
                  onClick={(ev) => {
                    console.log(" id =>> ", id);
                    deleteMember("dropout", id);
                  }}
                >
                  회원 탈퇴
                </Button>
                </div>

              </div>
            </div>
            <div className="h-0.2 bg-gray-300 w-full mt-3 lg:mr-3 ml-3"></div>
          </div>
        );
      });
   }
  };

  const getSearchUserListItem = (listData: Array<searchUserDto>) => {
    if (listData.length === 0) {
      return (
        <div className="flex flex-col justify-center items-center mt-8">
          <p className="text-2xl">회원이 없습니다.</p>
        </div>
      );
    } else {
      return listData.map((each, idx) => {
        console.log(" search each !! >> ", each);
        let id = each.id;
        let position = each.positionClub ? each.positionClub: each.positionFreeClub;
        if(!position){
          position = "미입력";
        }
        let nameNPosition = each.name + " / " + position;
        let job = each.job;
        let address = each.address;
        let mobileNum = each.mobileNum;
        if(each.mobileNum){
          mobileNum = "H.P) " + mobileNum.substr(0, 3) +"-" +mobileNum.substr(3, 4) +"-" +mobileNum.substr(7, 4);
        }else{
          mobileNum = "";
        }
        let imgUrl = getMemberImgSimple(each.belongToJigu, each.imgName, each.gender)
        return (
          <div>
            {idx === 0 ? (
              <div className="h-0.2 bg-gray-300 w-full mt-3 lg:mr-3 ml-3"></div>
            ) : null}
            <div className="flex flex-row items-center w-full mt-5" onClick={(ev: any) => handleDetailSearch(each)}>
              <div>
                <img src={imgUrl} alt="" className="w-20 h-20 ml-5 mr-7" />
              </div>
              <div className="flex flex-col w-64 lg:w-124">
                <div className="h-5 w-36">
                  <span>{nameNPosition}</span>
                </div>
                <div className="bg-gray-700 w-60"></div>
                <div className="h-30 bg-blue-100 mt-3 p-3 rounded-xl w-60 lg:w-124 ">
                  <p>
                    {getPositionIn(
                      each.positionJigu,
                      each.positionFreeJigu,
                      "지구"
                    )}{" "}
                    {getPositionIn(
                      each.positionJiyeok,
                      each.positionFreeJiyeok,
                      "지역"
                    )
                      ? "  " +
                        getPositionIn(
                          each.positionJiyeok,
                          each.positionFreeJiyeok,
                          "지역"
                        )
                      : ""}
                  </p>
                  <p>{job}</p>
                  <p>{address}</p>
                  <p>
                    <span>{mobileNum}</span>
                  </p>
                  <p>
                    <span>
                      {"소속 : " +
                        each.jiguName +
                        "지구 " +
                        each.jiyeokName +
                        " " +
                        each.jidaeName +
                        " " +
                        each.clubName}
                    </span>
                  </p>
                  <p className={each.dropout ? "text-red-500 font-semibold" : "hidden"}>[퇴회회원]</p>
                </div>
              </div>

              <div className="mt-2 ml-2 lg:ml-4">
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<DeleteIcon />}
                    onClick={(ev) => {
                        console.log(" id =>> ", id);
                        deleteMember("delete",id);
                    }}
                >삭제</Button></div>
            </div>
            <div className="h-0.2 bg-gray-300 w-full mt-3 lg:mr-3 ml-3"></div>
          </div>
        );
      });
    }
  };

  const getClubListItem = (listData: Array<clubDto>) => {
    if (listData.length === 0) {
      return (
        <div className="flex flex-col justify-center items-center mt-8">
          <p className="text-2xl">클럽이 없습니다.</p>
        </div>
      );
    } else {
      return listData.map((each, idx) => {
        // console.log(" each >> ", each);
        let id = each.id;

        let jidaeName = each.jidaeName;
        let jiyeoName = each.jiyeokName;
        let jiguName = each.jiguName;
        let sponsorclubName = each.sponsorClubName;

        let clubName = each.name ? each.name : "";
        let startDay = each.startDay ? each.startDay : "";
        let jiguNo = each.jiguNo ? each.jiguNo : "";
        let gukjeNo = each.gukjeNo ? each.gukjeNo : "";
        let address = each.address ? each.address : "";
        let tel = each.tel ? "전화: " + each.tel : "";
        let fax = each.fax ? "팩스: " + each.fax : "";
        let homepage = each.homepage ? "홈페이지: " + each.homepage : "";
        let email = each.email ? " 이메일: " + each.email : "";
        let slogan = each.slogan ? each.slogan : "";
        let clubOnly = each.clubOnly ? each.clubOnly : "";

        return (
          <div>
            {idx === 0 ? (
              <div className="h-0.2 bg-gray-300 w-96 lg:w-full mt-3 lg:mr-3 ml-3"></div>
            ) : null}
            <div className="flex flex-row items-center w-96 lg:w-full mt-5">
              <div className="flex flex-col w-64 lg:w-124">
                <div className="h-5 w-40">
                  <span>{clubName}</span>
                </div>
                <div className="bg-gray-700 w-60"></div>
                <div className="h-30 bg-blue-100 mt-3 p-3 rounded-xl w-60 lg:w-124 ">
                  <p>
                    소속: {jiguName}지구 {jiyeoName} {jidaeName}{" "}
                  </p>
                  <p>
                    창립일: {startDay} / 지구 번호: {jiguNo} / 국제 번호:{" "}
                    {gukjeNo}
                  </p>
                  <p>클럽주소: {address}</p>
                  <p>
                    {tel} {fax}
                  </p>
                  <p>스폰서 클럽: {sponsorclubName}</p>
                  <p>
                    {homepage} {email}
                  </p>
                </div>
              </div>
            </div>
            <div className="h-0.2 bg-gray-300 w-full mt-3 lg:mr-3 ml-3"></div>
          </div>
        );
      });
    }
  };

  const getDropoutMemberList = (listData:Array<any>) => {
    if (listData.length === 0) {
      return (
        <div className="flex flex-col justify-center items-center mt-8">
          <p className="text-2xl">클럽이 없습니다.</p>
        </div>
      );
    } else {
      return listData.map((each, idx) => {
        return(<div className="flex flex-col">

        </div>)
      })
  }
}

  const addMember = () => {
    console.log("add Member!");
    setBelonging({
      jidae: jidae,
      jiyeok: jiyeok,
      jigu: jigu,
    });

    console.log("belonging >> ", belonging);
    setMember(-1);
    if (club !== 0 && club != null) {
      console.log(" jidae >>> ", jidae);
      console.log(" jiyeok >>> ", jiyeok);
      console.log(" jigu >>> ", jigu);
      console.log(" club >>> ", club);
      // history.push("/addMember", { clubId: club });
      setAddMemberModalOpen(true);
    } else {
      alert('클럽을 먼저 선택해 주세요');
    }
    // sendSMSByBBurio();
    // setAddMemberModalOpen(true);
  };



  const doLogout = () => {
    setLogoutSnackBarOpen(true);
  };

  const addClubInfo = () => {
    console.log("addClubInfo!");
    if (club !== 0 && club != null) {
      console.log(" club >>> ", club);
      setBelonging({
        jidae: jidae,
        jiyeok: jiyeok,
        jigu: jigu,
      });
      setAddClubInfoModalOpen(true);
    }else {
      alert('클럽을 먼저 선택해 주세요');
    }
  };

  const moveToNoticeList = () => {
    history.push('/noticeListJigu' ,{jigu:jigu, authLevel:authLevel});
  }

  const monthlyReport = () => {
    console.log("club >> ", club);
    history.push("/monthReport", { club: club });
  }

  const addNotice = () => {
    console.log("addNotice! >> ", club);
    if (club !== 0 && club != null) {
      console.log(" club >>> ", club);
      setAddNoticeModalOpen(true);
    }else if(club == 0 || club == null || club == undefined) {
      alert('클럽을 먼저 선택해 주세요');
    }
  };

  const sendAppInstallInfoSMSOpen = () => {
    console.log("sendAppInstallInfoSMS!");
    if (club !== 0 && club != null) {
      console.log(" club >>> ", club);
      setSendSmsModalOpen(true);
    }else if(club == 0 || club == null || club == undefined) {
      alert('클럽을 먼저 선택해 주세요');
    }
  };

  const seeDropoutMembers = async () => {
    if(showClubORMember == "member"){
      setShowClubORMember("dropoutMember")
    }else if(showClubORMember == "dropoutMember") {
      setShowClubORMember("member")
    }
   
    let result : Array<userDto> = [];
    if(club){
      // 퇴회회원 by 클럽
      result = (await axios.get(comm.SERVER_URL+"/member/dropoutMembers/belongToClub/"+club)).data;
    }else if(jidae){
      // 퇴회회원 by 지대
      result = (await axios.get(comm.SERVER_URL+"/member/dropoutMembers/belongToJidae/"+jidae)).data;
    }else if(jiyeok){
      // 퇴회회원 by 지역
      result = (await axios.get(comm.SERVER_URL+"/member/dropoutMembers/belongToJiyeok/"+jiyeok)).data;
    }else if(jigu){
      // 퇴회회원 by 지구
      result = (await axios.get(comm.SERVER_URL+"/member/dropoutMembers/belongToJigu/"+jigu)).data;
    }

    if(result){
      setDropoutMemberList(result);
    }
  };

  const downLoadMemberInfosByClub = async () => {
    console.log("downloadMembers !");
    if (club !== 0 && club != null) {
      console.log(" club >>> ", club);
      let result = await axios.get(comm.SERVER_URL+"/member/memberFileDownloadsByClub/"+club);
      console.log("filedownload >> ", result.data);
      saveAs(result.data.url, result.data.fileName);

    }else if(club == 0 || club == null || club == undefined) {
      alert('클럽을 먼저 선택해 주세요');
    }
  };

  const manageAdmin = () => {
    console.log("manageAdmin !");
    if (club !== 0) {
      console.log(" club >>> ", club);
      setManageAdminModalOpen(true);
    }
  };

  const goToDashBoard = () => {
    history.push("/dashboard", { jigu: jigu, authLevel:authLevel });
  };

  const deleteMember = (type: string, id: number) => {
    console.log("delete Member!");
    setDeleteMemberSnackBarOpen(true);
    setDeleteInfo({ type: type, id: id });
  };

  const searchViaMember = async () => {
    setShowClubORMember("searchMember");
    let result = await axios.get(
      comm.SERVER_URL + "/member/searchViaMember/" + searchKeyword +"/" +jigu
    );
    console.log(" result >> ", result.data);
    setSearchUserList(result.data);
  };

  const searchViaClub = async () => {
    setShowClubORMember("club");
    let result = await axios.get(
      comm.SERVER_URL + "/member/searchViaClub/" + searchKeyword
    );
    console.log(" result data >> ", result.data);
    setSearchClubrList(result.data);
  };

  
  const addClub = async () => {
    console.log(" clubInputName >> ", clubInputName);
    console.log(" belongTo >> ", jidae);
    console.log(" jiyeokId >> ", jiyeok);
    console.log(" jiguId >> ", jigu);
    let result  =  await axios.post(comm.SERVER_URL+"/group/club/addClub", {'name':clubInputName, 'belongTo': jidae, 'jiyeokId':jiyeok, 'jiguId':jigu} )  
    console.log("result >> ", result.data);
  };

  const modifyClub = async (idToModify:number) => {
    console.log(" idToModify >> ", idToModify);
    let result  =  await axios.put(comm.SERVER_URL+"/group/club/addClub", {'id': idToModify, 'name':clubInputName, 'belongTo': jidae, 'jiyeokId':jiyeok, 'jiguId':jigu} )  
    console.log("result >> ", result.data);
  };

  const handleSearchKeyword = (ev: any) => {
    setSearchKeyword(ev.target.value);
  };

  return (
    <div className="flex flex-col pl-2 mb-10 w-96 lg:w-full lg:ml-5">
      <div id="searchClub"></div>
      <div id="groupArea" className="grid grid-cols-2 sm:flex flex-row m-4">
        <div id="jigu" className="flex flex-col">
          <span>지구</span>
          <select
            className="input mt-2"
            name="inpJigu"
            onChange={handleJiguChange}
            value={jigu}
            disabled={authLevel < 3 ? true : false}
          >
            {jiguList.map((jigu: { id: number; name: string }, idx: number) => {
              return <option value={jigu.id}>{jigu.name}</option>;
            })}
          </select>
        </div>
        <div id="jiyeok" className="flex flex-col">
          <span>지역</span>
          <select
            className="input mt-2"
            name="inpJiyeok"
            onChange={handleJiyeokChange}
            value={jiyeok}
            disabled={authLevel < 3 ? true : false}
          >
            {jiyeokList.map((jiyeok: { id: number; name: string }) => {
              return <option value={jiyeok.id}>{jiyeok.name}</option>;
            })}
          </select>
        </div>
        <div id="jidae" className="flex flex-col">
          <span>지대</span>
          <select
            className="input mt-2"
            name="inpJidae"
            onChange={handleJidaeChange}
            value={jidae}
            disabled={authLevel < 3 ? true : false}
          >
            {jidaeList.map((jidae: { id: number; name: string }) => {
              return <option value={jidae.id}>{jidae.name}</option>;
            })}
          </select>
        </div>
        <div id="club" className="flex flex-col">
          <div className="flex flex-row items-center">
            <span>클럽</span> 
            {/* <button className={isSuperUser ? "h-6 w-18 ml-2 border-blue-400 border rounded-md" : 'hidden'}
              onClick={(ev) => {setAddClubModalOpen(true)}}>관리</button> */}
          </div>
          <select
            className="input mt-2"
            name="inpClub"
            onChange={handleClubChange}
            value={club}
            disabled={authLevel < 3 ? true : false}
          >
            {clubList.map((club: { id: number; name: string }, idx) => {
              return (
                <option value={club.id} label={club.name}>
                  {club.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <div id="memberList" className="w-256 flex flex-col">
        <div className="flex flex-col ml-5 lg:flex-row lg:items-center">
          <div className={authLevel >= 3 ? "" : "hidden"}>
            {/* <span className='ml-2 text-sm lg:text-lg lg:ml-5'>* 검색</span> */}
            <input
              type="text"
              name="inputName"
              className="h-9 w-48 pl-2 border-gray-400 border"
              placeholder="회원 또는 클럽이름으로 검색"
              onChange={handleSearchKeyword}
            />
            <button
              className="h-8 w-18 ml-2 border-gray-200 border rounded-lg bg-blue-600"
              onClick={searchViaMember}
            >
              <span className="text-white text-sm">회원 검색</span>
            </button>
            <button
              className="h-8 w-18 ml-2 border-gray-200 border rounded-lg bg-green-600"
              onClick={searchViaClub}
            >
              <span className="text-white text-sm">클럽 검색</span>
            </button>
          </div>

          <div className="flex flex-row lg:mr-7 mt-2 lg:mt-0">
            <div className={authLevel >= 3 ? "hidden": "ml-0 mb-2"}>
              <Button
                variant="contained"
                color="default"
                // startIcon={<AddAlert />}
                onClick={monthlyReport}
                className="h-10"
              >
                월간보고 및 회원입회퇴회
              </Button>
            </div>
            <div className="w-3"></div>
            <Button
              variant="contained"
              color="default"
              startIcon={<AddAlert />}
              onClick={moveToNoticeList}
              className="h-10"
            >
              지구공지 확인
            </Button>
            <div className="w-3"></div>
            <Button
              variant="contained"
              color="default"
              startIcon={<Description />}
              onClick={addClubInfo}
              className="h-10"
            >
              클럽정보
            </Button>
            <div className="w-3"></div>
            <div className={authLevel >= 3 ? "" : "hidden"}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<PersonAdd />}
                onClick={addMember}
              >
                회원추가
              </Button>
            </div>
            <div className="w-3"></div> 
          </div>
        </div>
        <div className="flex flex-row items-center">
          <div className="ml-5 text-sm lg:text-lg lg:ml-5">
            회원 수: {userList.length}
          </div>
          <div className="flex flex-row-reverse items-center mb-2 mt-3 mr-2 ml-24 lg:mr-7">
            <Button
                variant="contained"
                color="default"
                startIcon={<DropoutMember />}
                onClick={seeDropoutMembers}
              >
                {showClubORMember == "member" ? "퇴회회원" : "회원보기"}
              </Button>
            <div className="hidden lg:flex lg:flex-row-reverse lg:items-center">
              {/* <Button
                variant="contained"
                color="secondary"
                // startIcon={<LogoutIcon />}
                onClick={doLogout}
              >
                로그 아웃
              </Button> */}
              <div className="w-3"></div>
              <Button
                variant="contained"
                color="default"
                startIcon={<Send />}
                onClick={sendAppInstallInfoSMSOpen}
              >
                앱설치 안내 문자 전송
              </Button>
            </div>

      
            <div className={authLevel < 2 ? "w-3": 'hidden'}></div>
            <div className={authLevel < 2 ? "": 'hidden'}>
            {/* <div className="w-3"></div> */}
              <Button
                variant="contained"
                color="default"
                startIcon={<FileDownloadIcon />}
                onClick={downLoadMemberInfosByClub}
              >
                회원 엑셀 다운로드
            </Button>
            </div>
            
            <div className="w-3"></div>
            {authLevel >= 3 ? (
              <Button
                variant="contained"
                color="default"
                startIcon={<AccountBoxIcon />}
                onClick={manageAdmin}
              >
                ADMIN 관리
              </Button>
            ) : null}
            <div className="w-3"></div>
            {authLevel >= 3 ? (
              <Button
                variant="contained"
                color="default"
                onClick={goToDashBoard}
              >
                대쉬보드 이동
              </Button>
            ) : null}
          </div>
        </div>
        <div className="flex flex-row items-center ml-2 lg:hidden">
          {/* <Button
            variant="contained"
            color="default"
            startIcon={<Send />}
            onClick={sendAppInstallInfoSMSOpen}
          >
            앱설치 안내 문자 전송
          </Button> */}
          {/* <div className="w-3"></div> */}
          {/* <Button
            variant="contained"
            color="secondary"
            // startIcon={<LogoutIcon />}
            onClick={doLogout}
          >
            로그 아웃
          </Button> */}
        </div>
        {showClubORMember == "member" ? getListItem(userList) : showClubORMember == "searchMember" ? getSearchUserListItem(searchUserList)
          : showClubORMember == "club" ? getClubListItem(searchClubList) : showClubORMember == "dropoutMember" ? getListItem(dropoutMemberList) : null
        }
      </div>
      {DeleteWarningSnackBar()}
      {LogoutWarningSnackBar()}
      {AddMemberTransitionsModal()}
      {AddClubInfoTransitionsModal()}
      {AddNoticeTransitionsModal()}
      {SendSmsTransitionsModal()}
      {ManageAdminTransitionModal()}
      {BasicModal({
                modalOpen: addClubModalOpen, handleClose: () => { setAddClubModalOpen(false) },
                uiToApply:
                  <div className="flex flex-col items-center">
                    {
                      clubList.map(club =>{
                        return <div className="flex flex-row items-center">
                          <input type="text" className="input w-32 h-7" value={club.name}/>
                          <select
                            className="input h-7 w-14"
                            name="inpJiyeok"
                            onChange={changeJiyeok}
                            value={jiyeok}
                            disabled={authLevel < 3 ? true : false}
                          >
                            {jiyeokList.map((jiyeok: { id: number; name: string }) => {
                              return <option value={jiyeok.id}>{jiyeok.name}</option>;
                            })}
                        </select>
                        <select
                          className="input h-7 w-14"
                          name="inpJidae"
                          onChange={changeJidae}
                          value={jidae}
                          disabled={authLevel < 3 ? true : false}
                        >
                          {jidaeList.map((jidae: { id: number; name: string }) => {
                            return <option value={jidae.id}>{jidae.name}</option>;
                          })}
                        </select>
                          <button className="h-7 w-12 ml-2 border-gray-200 border rounded-lg bg-red-200"
                        onClick={(ev) => {modifyClub(club.id)}}>수정</button>  
                        </div>
                      })
                    }
                    <div className='flex flex-row items-center w-68 p-2 border rounded-lg border-gray-300'>
                      <input type="text" name="addClubInput" className="input w-48 h-7 mr-2" onChange={(ev) => {setClubInputName(ev.target.value)}} />
                      <button className="h-8 w-12 ml-2 border-gray-200 border rounded-lg bg-blue-500"
                        onClick={addClub}>추가</button>  
                    </div>
                  </div>
            })}
    </div>
  );
}

export default AdminMain;
