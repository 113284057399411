import React, { useState, useEffect, useRef } from 'react';
import comm from '../../common';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { clubOption, gender, months, quitReason, volunteerTypes } from './selectItems';
import Select from "react-select";
import { Button, Checkbox, IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { BasicModal, changeMonthFormat, getMonthFormat, getObjFromOptionLabelInSelect, getYearMonth, inspectionArrayHasValue, useStyles } from '../common/util';
import { QuitUserDto, userDto } from '../mobile/memberList';
import { ChildProps, DropoutDto, IVolunteerActivity, ReportingClubDto } from '../common/dto';
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom';
import { BasicBlueButton, BasicRedButton } from '../common/commonUI';
import { saveAs } from 'file-saver';

const MonthlyReportTotal: React.FC<ChildProps> = (props: ChildProps) => {
    const [selectedMonth, setSelectedMonth] = useState({ value: 0, label: '달 선택' });
    const [selectedFileDownMonth, setSelectedFileDownMonth] = useState({ value: 0, label: '달 선택' });
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [noneReportDisabled, setNoneReportDisabled] = useState(false);
    const [deleteVolunteerId, setDeleteVolunteerId] = useState<number | undefined>(-1);
    const [deleteMemberId, setDeleteMemberId] = useState<number | undefined>(-1);
    const [chooseDeleteObject, setChooseDeleteObject] = useState("");
    
    const [registeredNewMember, setRegisteredNewMember] = useState(false);
    const [registeredQuitMember, setRegisteredQuitMember] = useState(false);
    const [registeredVoluteer, setRegisteredVoluteer] = useState(false);

    const [disableNoNewMember, setDisableNoNewMember] = useState(false);
    const [disableNoQuitMember, setDisableNoQuitMember] = useState(false);
    const [disableNoVolunteer, setDisableNoVoluteer] = useState(false);

    const [selectedQuitMember, setSelectedQuitMember] = useState<any>();
    
    const [selectedQuitReason, setSelectedQuitReason] = useState({ value: 0, label: '퇴회사유(코드) 선택' })
    const [selectedQuitApprovedDay, setSelectedQuitApproveDay] = useState("")
    const [quitName, setQuitName] = useState("");
    const [quitMemberNo, setQuitMemberNo] = useState("");

    const [showMonths, setShowMonths] = useState(false);

    const [clubMemberCnt, setClubMemberCnt] = useState(0);
    const [noneReport, setNoneReport] = useState(false);

    const [selectedGFCheckBox, setSelectedGFCheckBox] = useState("");
    const [selectedTRCheckBox, setSelectedTRCheckBox] = useState("");

    const [checkNoNewMember, setCheckNoNewMember] = useState(false);
    const [checkNoQuitMember, setCheckNoQuitMember] = useState(false);
    const [checkNoVolunteer, setCheckNoVolunteer] = useState(false);
    
    const [clubInfo, setClubInfo] = useState({ id: 0, belongTo: 0, jiyeokId: 0, jiguId: 0, name: "" });
    const [quickMemberQuit, setQuickMemberQuit] = useState(false);
    const [isOverDueDate, setIsOverDueDate] = useState(false);
    let yearMonth = getYearMonth();
    const { clubAssignId, userAssignId, userPhone } = useParams<any>();
    const history: any = useHistory();
    const [vActivitiesArr, setVactivitiesArr] = useState<Array<IVolunteerActivity>>([])
    const [newMemberArr, setNewMemberArr] = useState<Array<userDto>>([])
    const [quitMemberArr, setQuitMemberArr] = useState<Array<QuitUserDto>>([])
    
    const screenEndRef = useRef<HTMLDivElement | null>(null);
    const inputCursorRef = useRef<any>([]);
    
    const getClubId = () => {
        if (clubAssignId) {
            // console.log("clubAssignId >>> ", clubAssignId);
            return clubAssignId;
        } else if (history.location.state && history.location.state.club) {
            // console.log("history.location.state.club >>> ", history.location.state.club);
            return history.location.state.club;
        } else {
            return 0;
        }
    }

    const clubId = getClubId();
    // console.log("clugId >>> ", clubId);

    const getComparedMonth = async () => {
        let today = dayjs();
        let thisMonth = dayjs().format('YYYY-MM');
        // console.log("thisMonth >> ", thisMonth);
        const recentDueDate = await axios.get(comm.SERVER_URL + "/monthReport/recentDueDate/" + thisMonth);
        let dueDate = dayjs(recentDueDate.data.result);
        // console.log("diff > ", dueDate.diff(today, 'days'));
        // console.log("next month > ", dueDate.add(1, 'month').format('YYYY-MM-DD'));

        if (dueDate.diff(today, 'days') >= 0) { // 마감일 이전 이면
            // console.log("yearMonth >> ", yearMonth)
            setSelectedMonth(yearMonth[0]);  // 이번달 보여줌

        } else {
            // setSelectedMonth({value: yearMonth.length, label: dayjs().add(1,'month').format('YYYY.MM')}) // 다음달 보여줌       
            setIsOverDueDate(true)
            // setSelectedMonth(yearMonth[0]);  
            alert("월간 보고 마감일이 지났습니다. 마감일 후에는 월간 보고가 올라가지 않습니다.");
            
            // history.push('/adminMain')     
        }

    }

    useEffect(() => {
        if (clubId == 0) {
            history.push('/admin');
            return;
        }
        getComparedMonth()
        getClubInfo();
        // setSelectedMonth(yearMonth[0]);
    }, []);

    useEffect(() => {
        if (clubId == 0) {
            history.push('/admin');
            return;
        }
        // getUserList(clubId);   // 기존 회원들
        getNewMembers();  // 입력 회원들
        getQuitMembers();  // 퇴회 회원들
        getVolunteerList(); // 봉사활동
        getReportingClub(); // 클럽보고
        console.log("selectedMonth >> ", selectedMonth);
    }, [selectedMonth])


    const getClubInfo = () => {
        axios.get(comm.SERVER_URL + "/group/clubInfo/", { params: { id: clubId } }).then(res => {
            let result = res.data;
            console.log(" result >>> ", result);
            setClubInfo({
                id: result?.id,
                belongTo: result?.belongTo,
                jiyeokId: result?.jiyeokoId,
                jiguId: result?.jiguId,
                name: result?.name
            })
        })
    }

    useEffect(() => {
        if (clubId == 0) {
            history.push('/admin');
            return;
        }
        if (vActivitiesArr.length == 0) {
            // addVolunteer();
            setVactivitiesArr([...vActivitiesArr, { type: { value: 0, label: "봉사타입 선택" }, activitydate: "", cost: 0, activity: "", clubId: clubId, month: changeMonthFormat(selectedMonth.label), reportingMonth: changeMonthFormat(selectedMonth.label) }]);
        }

    }, [vActivitiesArr]);
    
    useEffect(() => {
        if (clubId == 0) {
            history.push('/admin');
            return;
        }
        if (newMemberArr.length == 0) {
            // addNewMember();
            setNewMemberArr([...newMemberArr,
                { name: "", englishName: "", birthYear: "", memberTypeArr: [], job: "", sponsorName: "", mobileNum: "", positionClub: "", memberNo: "", startDay: "", zipCode: "", address: "", approvalDay: "", belongToClub: 0, gender: "", reportingMonth: changeMonthFormat(selectedMonth.label) }])
        }

    }, [newMemberArr]);

    useEffect(() => {
        if (clubId == 0) {
            history.push('/admin');
            return;
        }
        if (quitMemberArr.length == 0) {
            // addQuitMember();
            setQuitMemberArr([...quitMemberArr, { name: "", memberNo: "", quitReason: "", dropout: "", approvalDay: "", belongToClub: 0, reportingMonth: changeMonthFormat(selectedMonth.label) }])
        }

    }, [quitMemberArr]);

    useEffect(()=>{
        if(noneReport == true){
            screenEndRef.current?.scrollIntoView({ behavior: 'smooth' });
            setCheckNoNewMember(true);
            setCheckNoQuitMember(true);
            setCheckNoVolunteer(true);
        }else{
            setCheckNoNewMember(false);
            setCheckNoQuitMember(false);
            setCheckNoVolunteer(false);
        }
        console.log("noneReport >> ", noneReport);
    },[noneReport])

    useEffect(()=>{ 
        if(registeredNewMember || registeredQuitMember || registeredVoluteer){
            setNoneReportDisabled(true);
        }else{
            setNoneReportDisabled(false);
        }

        if(registeredNewMember){
            setDisableNoNewMember(true)
        }else{
            setDisableNoNewMember(false)   
        }

        if(registeredQuitMember){
            setDisableNoQuitMember(true)   
        }else{
            setDisableNoQuitMember(false)   
        }

        if(registeredVoluteer){
            setDisableNoVoluteer(true)   
        }else{
            setDisableNoVoluteer(false)   
        }

    },[registeredNewMember, registeredQuitMember, registeredVoluteer])

    useEffect(()=>{ 
        if(checkNoNewMember && checkNoQuitMember && checkNoVolunteer){
            setNoneReport(true)
        }else{
            setNoneReport(false)
        }

        // if(checkNoNewMember){
        //     setNewMemberArr([]);
            
        // }
        // if(checkNoQuitMember){
        //     setQuitMemberArr([]);

        // }
        // if(checkNoVolunteer){
        //     setVactivitiesArr([]);
        // }
    },[checkNoNewMember, checkNoQuitMember, checkNoVolunteer])

    const addVolunteer = () => {
        if(noneReport){
            alert("보고 내용 없음 체크를 해제하고 입력하세요");
            return;
        }else{
            setVactivitiesArr([...vActivitiesArr, { type: { value: 0, label: "봉사타입 선택" }, activitydate: "", cost: 0, activity: "", clubId: clubId, month: changeMonthFormat(selectedMonth.label), reportingMonth: changeMonthFormat(selectedMonth.label) }])
        }
    }

    const addNewMember = () => {
        if(noneReport){
            alert("보고 내용 없음 체크를 해제하고 입력하세요");
            return;
        }else{
            setNewMemberArr([...newMemberArr,
            { name: "", englishName: "", birthYear: "", memberTypeArr: [], job: "", sponsorName: "", mobileNum: "", positionClub: "", memberNo: "", startDay: "", zipCode: "", address: "", approvalDay: "", belongToClub: 0, gender: "", reportingMonth: changeMonthFormat(selectedMonth.label) }])
        }
    }

    const addQuitMember = () => {
        if(noneReport){
            alert("보고 내용 없음 체크를 해제하고 입력하세요");
            return;
        }else{
            setQuitMemberArr([...quitMemberArr, { name: "", memberNo: "", quitReason: "", dropout: "", approvalDay: "", belongToClub: 0, reportingMonth: changeMonthFormat(selectedMonth.label) }])
        }
    }

    const getNewMembers = async () => {
        let reportingMonth = changeMonthFormat(selectedMonth.label);
        console.log("clubId >> ", clubId);
        console.log("reportingMonth >> ", reportingMonth);
        console.log("url >> ", comm.SERVER_URL + "/group/usersInMonthlyReport/" + clubId + "/" + reportingMonth);
        let inputUsers = await axios.get(comm.SERVER_URL + "/group/usersInMonthlyReport/" + clubId + "/" + reportingMonth);
        console.log("inputUsers >> ", inputUsers);
        if(inputUsers.data.length > 0){
            setNoneReport(false);
            setNoneReportDisabled(true);
            setRegisteredNewMember(true);
        }else{
            setRegisteredNewMember(false);
        }
        console.log("inputUsers >> ", inputUsers);
        setNewMemberArr(inputUsers.data);
    }

    const getQuitMembers = async () => {
        let reportingMonth = changeMonthFormat(selectedMonth.label);
        let quitUsers = await axios.get(comm.SERVER_URL + "/group/quitUsersInReport/" + clubId + "/" + reportingMonth);
        console.log("quitUsers >> ", quitUsers);
        if(quitUsers.data.length > 0){
            setNoneReport(false);
            setNoneReportDisabled(true);
            setRegisteredQuitMember(true);
        }else{
            setRegisteredQuitMember(false);
        }
        setQuitMemberArr(quitUsers.data);
    }

    const getVolunteerList = async () => {
        const getSelectTypeValue = (label: string) => {
            let value = 0;
            volunteerTypes.forEach(eachType => {
                if (eachType.label == label) {
                    value = eachType.value;
                }
            })
            return value;
        }

        let month = changeMonthFormat(selectedMonth.label);
        let result = await axios.get(comm.SERVER_URL + "/volunteer/byclubmonth/" + clubId + "/" + month);
        
        if(result.data.length > 0){
            setNoneReport(false);
            setNoneReportDisabled(true);
            setRegisteredVoluteer(true);
        }else{
            setRegisteredVoluteer(false);
        }

        setVactivitiesArr(result.data.map((each: any) => {
            return { ...each, type: { value: getSelectTypeValue(each.type), label: each.type } }
        }));
        return result.data.length;
    }

    const getReportingClub = async () => {
        let reportingMonth = changeMonthFormat(selectedMonth.label);
        let reportingClub = await axios.get(comm.SERVER_URL + "/monthReport/reportingClub/" + clubId + "/" + reportingMonth);
        console.log("reportingClub >> ", reportingClub.data?.reportingClub);
        setClubMemberCnt(reportingClub.data?.reportingClub?.reportedMember)
        setNoneReport(reportingClub.data?.reportingClub?.isNone)
    }

    const onSelectVolunteer = (ev: any, idx: number) => {
        setVactivitiesArr(vActivitiesArr.map((eachVol, index) => {
            return index === idx ? { ...eachVol, type: ev } : eachVol
        }))
    }

    const DeleteWarningSnackBar = () => {
        const handleDelete = () => {
            if (chooseDeleteObject == "vol") {
                axios.delete(comm.SERVER_URL + "/volunteer", {
                    params: {
                        id: deleteVolunteerId
                    }
                }).then(res => {
                    console.log("handleDeleteVolunteer res => ", res.data);
                    if (res.data.ok) {
                        setSnackBarOpen(false);
                        getVolunteerList();
                    }
                });
            } else if (chooseDeleteObject == "member") {
                axios.delete(comm.SERVER_URL + "/member", {
                    params: {
                        id: deleteMemberId
                    }
                }).then(res => {
                    console.log("handleDeleteMember res => ", res.data);
                    if (res.data.ok) {
                        setSnackBarOpen(false);
                        getNewMembers();
                    }
                });
            } else if (chooseDeleteObject == "quit") {
                console.log("selectedQuitMember >> ", selectedQuitMember);
                const dropoutRequest: DropoutDto = {
                    name:selectedQuitMember.name,
                    id: selectedQuitMember.id,
                    memberNo:selectedQuitMember.memberNo,
                    quitReason: "",
                    approvalDay: "",
                    reportingMonth: "",
                }
                axios.post(comm.SERVER_URL + "/member/dropout/", dropoutRequest).then(res => {
                    console.log("dropout res => ", res.data);
                    if (res.data.ok) {
                        getQuitMembers();
                    }
                });

                setSnackBarOpen(false);
            }
        };

        const handleClose = () => {
            setSnackBarOpen(false);

        };

        const deleteVol = "삭제 진행 버튼을 클릭하시면 해당 활동이 지구 본부 DB에서도 삭제됩니다. 정말로 삭제 하시겠습니까?";
        const deleteMember = "삭제 진행 버튼을 클릭하시면 해당 회원이 지구 본부 DB에서도 삭제됩니다. 정말로 삭제 하시겠습니까?"
        const quitMember = "퇴회 취소 버튼을 클릭하시면 해당 회원의 퇴회 처리가 취소 됩니다. 정말로 진행 하시겠습니까?"

        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    style={{ backgroundColor: '#000000' }}
                    open={snackBarOpen}
                    //   autoHideDuration={6000}
                    onClose={handleClose}
                    message={chooseDeleteObject == "vol" ? deleteVol : chooseDeleteObject == "quit" ? quitMember : deleteMember}
                    action={
                        <div className="flex flex-row justify-start items-center">
                            <Button color="secondary" variant="contained" size="medium" onClick={handleDelete}>
                                {chooseDeleteObject == "quit" ? "퇴회 취소" : "삭제 진행"}
                            </Button>
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    }
                />
            </div>
        );
    };

    const checkDisableCheckBox = (checkBoxName: string) => {
        if (checkBoxName == '일반회원') {
            if (selectedGFCheckBox == "가족회원") {
                return true
            }
        }

        if (checkBoxName == "가족회원") {
            if (selectedGFCheckBox == "일반회원") {
                return true
            }
        }

        if (checkBoxName == "재입회원") {
            if (selectedTRCheckBox == "전입회원") {
                return true
            }
        }

        if (checkBoxName == "전입회원") {
            if (selectedTRCheckBox == "재입회원") {
                return true
            }
        }
    }

    const handleMemberInput = (ev: any, idx: number, target: string) => {
        if (target == 'gender' || target == 'positionClub') {
            setNewMemberArr(newMemberArr.map((member, index) => {
                return index === idx ? { ...member, [target]: ev.label } : member
            }))
        } else if (target == 'memberTypeArr') {
            console.log("ev.target >> ", ev.target.name)
            setNewMemberArr(newMemberArr.map((member, index) => {
                return index === idx ? { ...member, [target]: member.memberTypeArr.includes(ev.target.name) ? member.memberTypeArr.filter(each => { return each != ev.target.name }) : [...member.memberTypeArr, ev.target.name] } : member
            }))
        } else {
            setNewMemberArr(newMemberArr.map((member, index) => {
                return index === idx ? { ...member, [target]: ev.target.value } : member
            }))
        }
    }

    const handleQuitMemberInput = (ev:any, idx:number, target:string) => {
        console.log("ev target value >> ", ev)
        console.log("target >> ", target)
        if (target == 'quitReason') {
            setQuitMemberArr(quitMemberArr.map((member, index) => {
                return index === idx ? { ...member, [target]: ev.label } : member
            }))
        }else if(target=='approvalDay'){
            setQuitMemberArr(quitMemberArr.map((member, index) => {
                return index === idx ? { ...member, [target]: ev.target.value, 'dropout':ev.target.value } : member
            }))
        }
        else if(target=='name'){
            setQuitMemberArr(quitMemberArr.map((member, index) => {
                return index === idx ? { ...member, [target]: ev.trim() } : member
            }));
        }else if(target=='memberNo'){
       
            setQuitMemberArr(quitMemberArr.map((member, index) => {
                return index === idx ? { ...member, [target]: ev.trim() } : member
            }));
        }else {
            setQuitMemberArr(quitMemberArr.map((member, index) => {
                return index === idx ? { ...member, [target]: ev } : member
            }));
        }
    }

    const handleRemoveVolunteerActivity = (idx: number) => {
        if (vActivitiesArr[idx].id) {
            setDeleteVolunteerId(vActivitiesArr[idx].id);
            setChooseDeleteObject("vol");
            setSnackBarOpen(true)
        } else {
            let idToDelete = vActivitiesArr[idx].id;
            setVactivitiesArr(vActivitiesArr.filter((activity, index) => { return index != idx }));
        }
    }

    const handleVDate = (ev: any, idx: number) => {
        setVactivitiesArr(vActivitiesArr.map((eachVol, index) => {
            return index === idx ? { ...eachVol, activitydate: ev.target.value } : eachVol
        }))
    }

    const handleCost = (ev: any, idx: number) => {

        setVactivitiesArr(vActivitiesArr.map((eachVol, index) => {
            return index === idx ? { ...eachVol, cost: ev.target.value } : eachVol
        }))
    }

    const handleActivityDetail = (ev: any, idx: number) => {
        setVactivitiesArr(vActivitiesArr.map((eachVol, index) => {
            return index === idx ? { ...eachVol, activity: ev.target.value } : eachVol
        }))
    }

    const saveAllVolunteer = async () => {
        if(noneReport){
            alert("보고 내용 없음을 체크 해제 후 다시 시도해주세요");
            return 'stop';
        }

        for(let i=0; i < vActivitiesArr.length; i++){
            if(!vActivitiesArr[i].activity || !vActivitiesArr[i].activitydate || vActivitiesArr[i].type.value == 0){
                alert("필수 입력값: 봉사 타입, 봉사 날짜, 봉사 내용, 봉사 금액을 입력하시거나 [봉사활동 내역 없음]을 체크하세요.");
                return 'stop';
            } 
        }

        console.log(" save all volunteer ");
        let saveDto = vActivitiesArr.map((each, index) => {
            return { ...each, type: each.type.label, month: changeMonthFormat(selectedMonth.label), reportingMonth: changeMonthFormat(selectedMonth.label) }
        })
        console.log("saveDto >> ", saveDto);
        let vCnt = saveDto.length;
        let result = await axios.post(comm.SERVER_URL + "/volunteer", saveDto);
        console.log(" result >> ", result.data);

        if (result.data.ok) {
            // alert(vCnt + "개의 월간 봉사활동 내역이 성공적으로 저장 되었습니다. 최종 반영 시에는 반드시 최하단의 월별보고 마감 버튼을 클릭하세요.")
            // props.fire()
            getVolunteerList();
        }
    }

    const saveNewMembers = async () => {
        if(noneReport){
            alert("보고 내용 없음을 체크 해제 후 다시 시도해주세요");
            return 'stop';
        }
        let resultCnt = 0;
        for (let i = 0; i < newMemberArr.length; i++) {
            newMemberArr[i].belongTo = clubId;
            newMemberArr[i].belongToClub = clubId;
            newMemberArr[i].belongToJidae = clubInfo.belongTo;
            newMemberArr[i].belongToJiyeok = clubInfo.jiyeokId;
            newMemberArr[i].belongToJigu = clubInfo.jiguId;
            newMemberArr[i].clubName = clubInfo.name;
            console.log(" newMember >> ", newMemberArr[i])

            // if (!newMemberArr[i].mobileNum) {
            //     alert("핸드폰 번호는 필수 입니다.");
            //     return;
            // }
            console.log("gender >> ", newMemberArr[i].gender)
            if(!newMemberArr[i].mobileNum || !newMemberArr[i].address || !newMemberArr[i].name || !newMemberArr[i].englishName || !newMemberArr[i].birthYear 
                || newMemberArr[i].gender=='성별 선택' || !newMemberArr[i].zipCode || !newMemberArr[i].approvalDay
                 || !newMemberArr[i].sponsorName || newMemberArr[i].memberTypeArr.length == 0){
                    alert("필수 입력 사항을 모두 입력하시거나 [입회 회원 없음]을 체크하세요.")
                    return 'stop';
                 }
            
            

            if (newMemberArr[i].id) {
                let updateResult = await axios.put(comm.SERVER_URL + "/member", newMemberArr[i]);
                if (updateResult.data.ok) {
                    resultCnt++;
                }

            } else {
                let saveResult = await axios.post(comm.SERVER_URL + "/member", newMemberArr[i]);
                if (saveResult.data.ok) {
                    resultCnt++;
                }
            }
        }
        if (resultCnt == newMemberArr.length) {
            // alert(`${resultCnt}명의 회원 정보가 성공적으로 저장되었습니다. 최종 반영 시에는 반드시 최하단의 월별보고 마감 버튼을 클릭하세요.`);
            getNewMembers();
        }
    }

    const saveQuitMembers = async () => {
        if(noneReport){
            alert("보고 내용 없음을 체크 해제 후 다시 시도해주세요");
            return 'stop';
        }
        console.log("#################################");
        let resultCnt = 0;
        for (let i = 0; i < quitMemberArr.length; i++) {
            quitMemberArr[i].belongTo = clubId;
            quitMemberArr[i].belongToClub = clubId;
            quitMemberArr[i].belongToJidae = clubInfo.belongTo;
            quitMemberArr[i].belongToJiyeok = clubInfo.jiguId;
            quitMemberArr[i].belongToJigu = clubInfo.jiguId;
            quitMemberArr[i].clubName = clubInfo.name;
            console.log(" quitMemberArr >> ", quitMemberArr[i])

            console.log(" quitMemberArr >> ", quitMemberArr[i].approvalDay)
            console.log(" quitMemberArr >> ", quitMemberArr[i].quitReason)
            console.log(" quitMemberArr >> ", quitMemberArr[i].name)


            
            if(!quitMemberArr[i].approvalDay || !quitMemberArr[i].quitReason || !quitMemberArr[i].name ){
                    alert("필수 입력 사항(회원 이름, 퇴회 사유, 승인일)을 모두 입력하시거나 [퇴회 회원 없음]을 체크하세요.")
                    return 'stop';
            }
            
            let quitResult = await axios.post(comm.SERVER_URL + "/member/dropout", quitMemberArr[i]);
            console.log(" quitResult >> ", quitResult)
            
            if (quitResult.data.ok) {
                resultCnt++;
            }
        }
        if (resultCnt == quitMemberArr.length) {
            // alert(`${resultCnt}명의 퇴회처리가 성공적으로 완료되었습니다. 최종 반영 시에는 반드시 최하단의 월별보고 마감 버튼을 클릭하세요.`);
            getQuitMembers();
        }
    }

    const removeNewMember = (idx: number) => {
        if (newMemberArr[idx].id) {
            // DB에서 지움, alert 띄움
            setChooseDeleteObject("member");
            setDeleteMemberId(newMemberArr[idx].id);
            setSnackBarOpen(true)
        } 

        setNewMemberArr(newMemberArr.filter((each, index) => {
            return idx != index
        }))

    }

    const removeQuitMember = (idx: number) => {
        if (quitMemberArr[idx].id) {
            // DB에서 지움, alert 띄움
            setChooseDeleteObject("quit");
            // setDeleteMemberId(quitMemberArr[idx].memberNo);
            setSelectedQuitMember(quitMemberArr[idx])
            setSnackBarOpen(true)
        } else {
            setQuitMemberArr(quitMemberArr.filter((each, index) => {
                return idx != index
            }))
        }

    }

    const handleMemberTypeCheckBox = (ev: any, index: number) => {
        handleMemberInput(ev, index, 'memberTypeArr');
        if (ev.target.checked) {
            if (ev.target.name == "일반회원" || ev.target.name == "가족회원") {
                setSelectedGFCheckBox(ev.target.name);
            } else if (ev.target.name == "재입회원" || ev.target.name == "전입회원") {
                setSelectedTRCheckBox(ev.target.name);
            }
        } else {
            if (ev.target.name == "일반회원" || ev.target.name == "가족회원") {
                setSelectedGFCheckBox("");
            } else if (ev.target.name == "재입회원" || ev.target.name == "전입회원") {
                setSelectedTRCheckBox("");
            }
        }
    }

    const memberBox = () => {
        return newMemberArr.map((each, index) => {
            return (
                <div className='border-2 rounded-lg p-2 mt-2 bg-indigo-100 w-90' id='mBox'>
                    <div className='flex flex-row justify-between items-center' id='titleNRemove'>
                    <div className='flex flex-row items-center'>입회 회원 정보<span className='text-sm ml-2'>(모든 항목 필수 입력)</span></div>
                        <div>
                            <button type="button" className="mr-2 rounded-xl border-red-600 border-solid border-2 border-opacity-60 w-12 h-8 ml-3 hover:bg-red-200"
                                onClick={(ev) => { removeNewMember(index) }}>삭제</button>
                        </div>
                    </div>
                    <div className='flex flex-row mt-2 items-center'>
                        <div className='flex flex-col'>
                            <span className='ml-2'>이름</span>
                            <input type="text" disabled={noneReport || checkNoNewMember} name="inputVolunteerDetail" className="input w-28" onChange={(ev) => { handleMemberInput(ev, index, 'name') }} defaultValue={each.name} />
                        </div>
                        <div className='flex flex-col ml-1'>
                            <span className='ml-2 mr-1'>영문 이름</span>
                            <input type="text" disabled={noneReport || checkNoNewMember} name="inputVolunteerDetail" className="input w-28 " onChange={(ev) => { handleMemberInput(ev, index, 'englishName') }} defaultValue={each.englishName} />
                        </div>
                        <div className='flex flex-col ml-1'>
                            <span className='ml-1'>핸드폰번호</span>
                            <input type="text" disabled={noneReport || checkNoNewMember} name="inputVolunteerDetail" className="input w-28" defaultValue={each.mobileNum} onChange={(ev) => { handleMemberInput(ev, index, 'mobileNum') }} />
                        </div>
                    </div>

                    <div className='flex flex-row mt-2 items-center'>
                        <div className='flex flex-col'>
                            <span className='ml-2'>생년월일</span>
                            <input type="date" disabled={noneReport || checkNoNewMember} placeholder='예)1968-05-05' name="inputVolunteerDetail" defaultValue={each.birthYear ? each.birthYear : '1970-06-01'} className="input w-40" onChange={(ev) => { handleMemberInput(ev, index, 'birthYear') }} />
                        </div>

                        <div className='flex flex-col ml-2'>
                            <span className='ml-2 mr-1'>성별</span>
                            <Select
                                isDisabled={noneReport || checkNoNewMember} 
                                value={each.gender == "남자" ? gender[1] : each.gender == "여자" ? gender[2] : gender[0]}
                                className="ml-1 w-28 mr-1 h-12"
                                name="inputGender"
                                isSearchable={false}
                                onChange={(ev) => { handleMemberInput(ev, index, 'gender') }}
                                options={gender} />
                        </div>
                    </div>

                    <div className='flex flex-row mt-2 items-center'>
                        <div className='flex flex-col'>
                            <span className='ml-2'>주소</span>
                            <input type="text" disabled={noneReport || checkNoNewMember} name="inputVolunteerDetail" className="input w-56" defaultValue={each.address} onChange={(ev) => { handleMemberInput(ev, index, 'address') }} />
                        </div>
                        <div className='flex flex-col ml-4'>
                            <span className='ml-2 mr-1'>우편번호</span>
                            <input type="text" disabled={noneReport || checkNoNewMember} name="inputVolunteerDetail" className="input w-24" defaultValue={each.zipCode} onChange={(ev) => { handleMemberInput(ev, index, 'zipCode') }} />
                        </div>
                    </div>

                    <div className='flex flex-row mt-2 items-center'>
                        <div className='flex flex-col'>
                            <span className='ml-1'>회원 구분</span>
                            <div className='flex flex-row ml-2 items-center mt-1'>
                                <label className='flex items-center'>
                                    <input type="checkbox" disabled={checkDisableCheckBox("일반회원") ||noneReport || checkNoNewMember} checked={each.memberTypeArr.includes("일반회원")} name="일반회원" className="h-6 w-6 mr-1 text-sm"
                                        onChange={(ev) => handleMemberTypeCheckBox(ev, index)} />일반
                                </label>
                                <label className='flex items-center'>
                                    <input type="checkbox" disabled={checkDisableCheckBox("가족회원") ||noneReport || checkNoNewMember}
                                        defaultChecked={each.memberTypeArr.includes("가족회원")} name="가족회원" className="h-6 w-6 ml-4 mr-1 text-sm"
                                        onChange={(ev) => { handleMemberTypeCheckBox(ev, index) }} />가족
                                </label>
                                {/* <div className='ml-3 text-lg font-bold'>/</div> */}
                                <span className='ml-3'>[전입,재입은 지구본부 문의]</span>
                                {/* <label className='flex items-center'>
                                    <input type="checkbox" disabled={checkDisableCheckBox("재입회원") ||noneReport || checkNoNewMember}
                                        defaultChecked={each.memberTypeArr.includes("재입회원")} name="재입회원" className="h-6 w-6 ml-4 mr-1 text-sm"
                                        onChange={(ev) => { handleMemberTypeCheckBox(ev, index) }} />재입
                                </label>
                                <label className='flex items-center'>
                                    <input type="checkbox" disabled={checkDisableCheckBox("전입회원") ||noneReport || checkNoNewMember}
                                        defaultChecked={each.memberTypeArr.includes("전입회원")} name="전입회원" className="h-6 w-6 ml-4 mr-1 text-sm"
                                        onChange={(ev) => { handleMemberTypeCheckBox(ev, index) }} />전입
                                </label> */}
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-row mt-2 items-center'>

                        <div className='flex flex-col ml-1'>
                            <span className='mr-1 ml-1'>이사회승인날짜</span>
                            <input type="date" disabled={noneReport || checkNoNewMember} defaultValue={each.approvalDay ? each.approvalDay : ""} className="input w-36" onChange={(ev) => { handleMemberInput(ev, index, 'approvalDay') }} />
                        </div>
                        <div className='flex flex-col ml-2'>
                            <span className='ml-1 mr-1'>스폰서 이름</span>
                            <input type="text" disabled={noneReport || checkNoNewMember} name="inputVolunteerDetail" className="input w-24" defaultValue={each.sponsorName} onChange={(ev) => { handleMemberInput(ev, index, 'sponsorName') }} />
                        </div>
                    </div>

                    <div className='w-full flex items-center justify-center mt-3'>
                        <button type='button' disabled={noneReport || checkNoNewMember} className={index == newMemberArr.length - 1 ? "rounded-md border-blue-400 border-solid border-2 bg-blue-500 w-80 h-10 mt-2 hover:border-blue-800 hover:text-black text-white" : "hidden"}
                            onClick={addNewMember}>회원 입회 입력란 추가</button>
                    </div>
                </div>
            )
        })
    }

    const onSelectQuitReason = (ev: any) => {
        setSelectedQuitReason(ev)
    }

    const onSelectQuitApprovalDay = (ev: any) => {
        setSelectedQuitApproveDay(ev.target.value)
    }

    const memberQuitBox = () => {
        return quitMemberArr.map((each, index) => {
            let reasonObj;
            quitReason.forEach(reason => {
                if(reason.label == each.quitReason){
                    reasonObj = reason;
                    return;
                }
            })
            // console.log("reasonObj => ", reasonObj);
            return (
                <div className='flex flex-col p-4 w-90 bg-blue-200 rounded-xl mt-2'>
                    <div className='flex flex-row justify-between items-center' id='titleNRemove'>
                        <div className='flex flex-row items-center'>퇴회 회원 정보<span className='text-sm ml-2'>(* 필수입력)</span></div>
                        <button type="button" className="mr-2 rounded-xl border-red-600 border-solid border-2 border-opacity-60 w-12 h-8 ml-3 hover:bg-red-200"
                            onClick={(ev) => { removeQuitMember(index) }}>삭제</button>
                    </div>
                    <div className='flex flex-row mt-2 items-center' >
                        <div className='flex flex-col'>
                            <div className='flex flex-row items-center'>
                                <span className=''>*회원명</span><span className='text-xs ml-2'>(성, 이름 붙여서)</span>
                            </div>
                            <input className='input p-1.5 w-44' disabled={noneReport || checkNoQuitMember} type='text' placeholder='홍 길동(X)->홍길동(O)' value={each.name} onChange={(ev) => { handleQuitMemberInput(ev.target.value, index, 'name') }} />
                        </div>
                        <div className='flex flex-col ml-2'>
                            <div className=''>*퇴회 사유(코드)</div>
                            <Select
                                isDisabled={noneReport || checkNoQuitMember}
                                value={reasonObj}
                                className="w-32"
                                name="inputVolunteer"
                                isSearchable={false}
                                onChange={(ev) => { handleQuitMemberInput(ev, index, 'quitReason') }}
                                options={quitReason} />
                        </div>
                    </div>
                    <div className='flex flex-row items-center mt-4'>
                        <div className='flex flex-col '>
                            <div className=''>*이사회 승인 날짜</div>
                            <input type="date" disabled={noneReport || checkNoQuitMember} name="inputQuitDate" className="input-date w-32 h-10"
                                onChange={(ev) => { handleQuitMemberInput(ev,index,'approvalDay') }} />
                        </div>
                        <div className='flex flex-col ml-2'>
                            <span className='ml-2'>회원번호</span><input className='input p-1.5 w-28 ml-1' disabled={noneReport || checkNoQuitMember} type='number' value={each.memberNo} onChange={(ev) => { handleQuitMemberInput(ev.target.value,index,'memberNo') }} />
                        </div>
                    </div>
                    <div className='flex flex-row items-center justify-center mt-4'>
                        <button type='button' className={"rounded-md border-blue-400 border-solid border-2 bg-blue-500 w-80 h-10 mt-2 hover:border-blue-800 hover:text-black text-white"}
                            disabled={noneReport || checkNoQuitMember} onClick={addQuitMember}>회원 퇴회 입력란 추가</button>
                    </div>
                </div>)
        })
    }

    const volunteerBox = () => {
        return vActivitiesArr.map((each, index) => {
            return (
                <div className='border-2 rounded-lg p-2 mt-2 bg-blue-100' id='vbox'>
                    <div className='flex flex-row justify-between items-center' id='titleNRemove'>
                        <div>활동한 봉사 내역</div>
                        <div>
                            <button type="button" className="mr-2 rounded-xl border-red-600 border-solid border-2 border-opacity-60 w-12 h-8 ml-3 hover:bg-red-200"
                                onClick={(ev) => { console.log("index >> ", index); handleRemoveVolunteerActivity(index) }}>삭제</button>
                        </div>
                    </div>
                    <div className='flex flex-row mt-2 items-center'>
                        <span className='ml-3 mr-3'>봉사 타입</span>
                        <Select
                            isDisabled={noneReport || checkNoVolunteer}
                            value={vActivitiesArr[index].type}
                            className="ml-1 w-32 mr-1"
                            name="inputVolunteer"
                            isSearchable={false}
                            onChange={(ev) => { onSelectVolunteer(ev, index) }}
                            options={volunteerTypes} />
                    </div>
                    <div className='flex flex-row mt-2 items-center'>
                        <span className='ml-3 mr-3'>봉사 날짜</span>
                        <input type="date" disabled={noneReport || checkNoVolunteer} name="inputVolunteerDate" className="input-date ml-1"
                            onChange={(ev) => { handleVDate(ev, index) }} value={each.activitydate.split('T')[0]} />
                    </div>

                    <div className='flex flex-row mt-2 items-center'>
                        <span className='ml-3 mr-3'>봉사 금액</span>
                        <input type="number" disabled={noneReport || checkNoVolunteer} name="inputCost" placeholder="금액 입력(숫자만)" className="input ml-1 w-32" onChange={(ev) => { handleCost(ev, index) }} value={each.cost} />
                    </div>
                    <div className='flex flex-row mt-2 items-center'>
                        <span className='ml-3 mr-3'>봉사 내용</span>
                        <input type="text" disabled={noneReport|| checkNoVolunteer} name="inputVolunteerDetail" className="input w-60 ml-1" onChange={(ev) => { handleActivityDetail(ev, index) }} value={each.activity} />
                    </div>
                    <div className='w-full flex items-center justify-center'>
                        <button type='button' className={index == vActivitiesArr.length - 1 ? "rounded-md border-blue-400 border-solid border-2 bg-blue-500 w-80 h-10 mt-2 hover:border-blue-800 hover:text-black text-white" : "hidden"}
                           disabled={noneReport || checkNoVolunteer} onClick={addVolunteer}>봉사 활동 입력란 추가</button>
                    </div>
                </div>
            )
        })
    }

    const reportComplete = async () => {
        console.log("clubMemberCnt >> ", clubMemberCnt);
        if(clubMemberCnt == 0 || clubMemberCnt == null || clubMemberCnt == undefined){
            alert("클럽 회원수 보고는 필수 입니다.")
            inputCursorRef.current.focus();
            return;
        }

        // if(checkNoNewMember == false && checkNoQuitMember == false && checkNoVolunteer == false && noneReport == false){
        //     alert("입회, 퇴회, 봉사 활동 내역을 입력하시거나, [보고 내용 없음]을 체크하셔야 합니다.");
        //     return;
        // }
        
        if(checkNoNewMember == false){
            let returnVal = await saveNewMembers();
            if(returnVal == 'stop'){
                return;
            }
        }
        if(checkNoQuitMember == false){
           let returnVal = await saveQuitMembers();
           if(returnVal == 'stop'){
            return;
           }
        }
        if(checkNoVolunteer == false){
            let returnVal = await saveAllVolunteer();
            if(returnVal == 'stop'){
                return;
            }
        }

        let finishReportVal = new Date().toISOString().split('T')[0];
        const clubReport: ReportingClubDto = {
            reportedMember: clubMemberCnt,
            clubId: clubInfo.id,
            reportingMonth: changeMonthFormat(selectedMonth.label),
            reportingDay: finishReportVal,
            isNone: noneReport,
            reporterMobile:userPhone,
        }
        console.log("clubReport >> ", clubReport);
        let saveClubReportResult = await axios.post(comm.SERVER_URL + "/monthReport/addReportingClub/", clubReport)
        console.log("saveReport >> ", saveClubReportResult);
        if (saveClubReportResult.data) {
            alert(`${clubInfo.name} ${clubReport.reportingMonth} 월별 보고가 완료 되었습니다.`)
        }
    }

    const downloadReport = async (monthToDown:string) => {
        console.log(" monthToDown >> ", monthToDown);
        let result = await axios.get(comm.SERVER_URL + "/common/makeClubReport/" + monthToDown + "/" + clubId);
        console.log(" result >> ", result.data)
        saveAs(result.data.url, result.data.fileName)
    }

    const showMonthList = () => {
        return (<Select
            value={selectedMonth}
            className='w-28 text-sm'
            onChange={(ev: any) => setSelectedMonth(ev)}
            options={yearMonth}
        />)
    }

    return (
        <div className='flex flex-col items-start ml-3 bg-white'>
            <div className='flex flex-col items-start mt-4'>
                <div className='font-semibold text-lg ml-2 mb-2'>{clubInfo.name}</div>
                <div className='flex flex-row items-center mb-2'>
                    <div className='text-2xl font-bold mr-6'>[{selectedMonth.label} 월간 보고]</div>
                    <BasicBlueButton btnText="보고서 다운로드" width="w-28" height="h-7" handleClick={(ev: any) => { setShowMonths(true) }} font="text-sm" />
                </div>
                <div className='bg-yellow-200 rounded-md p-1 mt-1 mb-1'>
                    <label className='flex items-center ml-3 mt-3 mb-3 mr-3 '>
                        <input type="checkbox" disabled={noneReportDisabled} className="h-6 w-6 mr-2 text-sm" checked={noneReport}
                            onChange={(ev: any) => {
                            console.log("ev.target.checked >> ", ev.target.checked)
                            setNoneReport(ev.target.checked) }} /><span className='font-semibold underline italic'>보고 내용 없음</span> <span className='text-sm text-red-600 ml-2'>(회원 변동 및 봉사내역 없음)</span>
                    </label>
                </div>
                <div className='flex flex-row items-center mt-3'>
                    <span className='text-lg'>* 회원 입회</span>
                    <label className='flex flex-row ml-32 items-center'>
                        <input type='checkbox' checked={checkNoNewMember} disabled={disableNoNewMember} className='w-5 h-5' onChange={(ev) => 
                            {
                                if(ev.target.checked){
                                    setNewMemberArr([]);
                                }
                                setCheckNoNewMember(ev.target.checked)
                            }}></input>
                        <span className='ml-2'>입회 회원 없음</span>
                    </label>
                </div>
                {memberBox()}
                {/* <div className='flex flex-col justify-center items-center mt-3 mb-3 '>
                    <button type='button' disabled={isOverDueDate} className='rounded-md border-red-200 border-solid border-2 bg-red-300 w-88 h-10 text-black' onClick={saveNewMembers}>회원 입회 내역 업로드</button>
                </div> */}

                <div className='flex flex-row items-center mt-4'>
                    <span className='text-lg'>* 회원 퇴회</span>
                    <label className='flex flex-row ml-32 items-center'>
                        <input type='checkbox' className='w-5 h-5' checked={checkNoQuitMember} disabled={disableNoQuitMember} onChange={(ev) => {
                            if(ev.target.checked){
                                setQuitMemberArr([])
                            }
                            setCheckNoQuitMember(ev.target.checked)
                        }}></input>
                        <span className='ml-2'>퇴회 회원 없음</span>
                    </label>
                </div>
                {memberQuitBox()}
                {/* <button type='button' disabled={isOverDueDate} className={"rounded-md border-red-200 border-solid border-2 bg-red-300 w-88 h-10 text-black mt-4"}
                    onClick={saveQuitMembers}>회원 퇴회 업로드</button> */}

                <div className='flex flex-row items-center mt-4'>
                    <span className='text-lg'>* 봉사활동 내역</span>
                    <label className='flex flex-row ml-16 items-center'>
                        <input type='checkbox' className='w-5 h-5' checked={checkNoVolunteer} disabled={disableNoVolunteer} onChange={(ev) => 
                            {
                                if(ev.target.checked){
                                    setVactivitiesArr([])
                                }
                                setCheckNoVolunteer(ev.target.checked);
                            }}></input>
                        <span className='ml-2'>봉사활동 내역 없음</span>
                    </label>
                </div>
                {volunteerBox()}
            </div>
            {/* <div className='flex flex-col justify-center items-center mt-3 mb-3 '>
                <button type='button' disabled={isOverDueDate} className='rounded-md border-red-200 border-solid border-2 bg-red-300 w-88 h-10 text-black' onClick={saveAllVolunteer}>봉사 활동 내역 업로드</button>
            </div> */}
            <div className='flex flex-col mt-4 mb-4 pl-2 pm-2 w-88' id='description' ref={screenEndRef}>
                <div className='mt-2 mb-2 p-2 bg-yellow-200 rounded-md'>
                    <span className='font-bold text-red-600 mr-2'>**(필수)</span>
                    <span className='underline font-semibold mr-2'>클럽 회원수 입력:</span>
                    <input type='number' ref={inputCursorRef} value={clubMemberCnt} className='input p-1.5 ml-2 w-14 h-8 border-gray-900' onChange={(ev: any) => { setClubMemberCnt(ev.target.value) }} />
                </div>

                {/* <div className='text-sm'>* 입회 회원이나 봉사 활동이 없는 경우는 공란으로 두시고 최종 월별 보고 완료 버튼을 클릭하시면 됩니다.</div> */}
                <div className='text-sm mt-2 italic underline text-red-600'>* 최종 월별 보고 완료 버튼을 클릭하셔야 지구본부에 최종적으로 보고 완료 됩니다.</div>
            </div>
            <div className='flex flex-col justify-center items-center mt-3 mb-3'>
                <button type='button' disabled={isOverDueDate} className='rounded-sm border-green-700 border-solid border-2 w-88 h-10 text-black font-bold mb-4' onClick={reportComplete}>최종 월별 보고 완료</button>
            </div>
            <div className={clubInfo.jiguId == 2 ? 'flex flex-col p-2 mb-6' : 'hidden'}>
                <span className='text-lg'>* 월간 보고 문의</span>
                <a href='tel:0312392020' className='italic underline mt-2 ml-2'>업무 문의: 031-239-2020 </a>
                <a href='tel:01082528008' className='italic underline mt-2 ml-2'>기술 문의: 010-8252-8008</a>
            </div>

            {DeleteWarningSnackBar()}
            {BasicModal({
                modalOpen: showMonths, handleClose: () => { setShowMonths(false) },
                uiToApply:
                    <div className='flex flex-col items-center p-2 border rounded-lg border-gray-300'>
                        <Select
                            value={selectedFileDownMonth}
                            className='w-28 text-sm'
                            onChange={(ev: any) => setSelectedFileDownMonth(ev)}
                            options={yearMonth}
                        />
                        <div className='h-3'></div>
                        <div className='flex flex-row items-center'>
                            <BasicBlueButton btnText='다운로드' width='w-16' height='h-8' font='text-sm' handleClick={(ev:any) => {downloadReport(changeMonthFormat(selectedFileDownMonth.label)); setShowMonths(false)}} />
                            <div className='w-2'></div>
                            <BasicRedButton btnText='취소' width='w-16' height='h-8' font='text-sm' handleClick={(ev:any) => {setShowMonths(false)}} />
                        </div>
                    </div>
            })}
        </div>
    )

}

export default MonthlyReportTotal;