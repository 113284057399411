import React, { useState, useEffect, useContext } from 'react';
import comm from '../../common';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import GroupContext from '../../context/groupContext';
import { BasicBlueButton, BasicRedButton } from '../common/commonUI';
import { BUCKET_NAME, REGION, getFormattedNow } from '../common/util';
import saveAs from 'file-saver';

interface INotice{
    id?:number,
    fileNames:Array<string>,
    title:string,
    content:string,
    fixedLevel:number,
    uploadTime:string,
} 

const NoticeDetail = () => {
    const history: any = useHistory();
    const {noticeId} = useParams<any>();
    const groupValue = useContext(GroupContext);
    const [notice, setNotice] = useState<INotice>();

    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    console.log("noticeId >> ", noticeId)
    // const notyId = history.location.state.id;
    let authLevel = 0;
    if(history.location.state){
        authLevel = history.location.state.authLevel;
    }


    console.log(" groupValue >> ", groupValue);
    useEffect(() => {
        axios.get(comm.SERVER_URL+"/notice/specific/"+noticeId).then(res => {
            setNotice(res.data);
        });
    },[]);

    const downloadFiles = (fileNames:Array<string>) => {
        fileNames.forEach(eachFileName => {
            let  eachUrl = `https://${BUCKET_NAME}.s3.${REGION}.amazonaws.com/${eachFileName}`;
            saveAs(eachUrl, eachFileName);
        })
    }

    const changeTitle = (ev:any) => {

    }

    const changeContent = (ev:any) => {

    }

    const removeNotice = (ev:any) => {
        console.log("ddddd")
        // eslint-disable-next-line no-restricted-globals
        let result = confirm("해당 공지가 삭제됩니다. 정말 진행하시겠습니까?");
        if(result){
            axios.delete(comm.SERVER_URL+"/notice", {
                params: {
                    id: noticeId
                }
            }).then(res => {
                console.log("deleteResult >> ", res.data);
                if(res.data){
                    history.goBack();
                }
            })
         
        }else{

        }

    }

    const getNoticeDetail = (eachNotice : INotice) => {
        return (<div className="flex flex-col rounded-md w-180 mt-2 border p-2 border-gray-400">
            <div className='flex flex-row justify-between'>
                <span className='text-lg ml-2' onChange={(ev:any) => {changeTitle(ev.target.value)}}>{eachNotice.title}</span>
                <span className='text-sm mr-2'>{eachNotice.uploadTime}</span>
            </div>
            <div className='ml-2 mb-4 mt-4' onChange={(ev:any) => {changeContent(ev.target.value)}}>{eachNotice.content}</div>
            {
               eachNotice.fileNames.length > 0 ? 
               <div className='flex flex-col ml-2'>
                <BasicBlueButton btnText='다운로드' width='w-16' height='h-8' font='text-sm' handleClick={(ev:any) => {downloadFiles(eachNotice.fileNames)}} />
                    <div className='flex flex-col mt-2 w-124 p-2'>
                        {
                            eachNotice.fileNames.map((eachFile : string) => {
                                return <div className='text-sm'>{eachFile}</div>
                            })
                        }
                    </div>
               </div> : null
            }
        </div>)
    }

    return (<div className='grid place-items-center'>
        <div className='text-2xl mt-10 mb-2 font-bold'>공지 상세</div>
        <div className={authLevel > 0 ? 'flex flex-col items-end' : 'hidden'}>
            <BasicRedButton btnText='삭제' width='w-16' height='h-8' font='text-sm' handleClick={removeNotice} />
            <div className='w-176'></div>
        </div>
        <div className='flex flex-col items-start'>
            {notice ? getNoticeDetail(notice) : null}
        </div>
    </div>)
}

export default NoticeDetail;
