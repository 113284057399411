import React, { useState, useEffect, useContext } from 'react';
import comm from '../../common';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import GroupContext from '../../context/groupContext';
import { BasicBlueButton } from '../common/commonUI';
import { getFormattedNow } from '../common/util';

interface INotice{
    id:number,
    fileNames:Array<string>,
    title:string,
    content:string,
    fixedLevel:number,
    uploadTime:string,
} 

const NoticeListJigu = () => {
    const history: any = useHistory();
    const groupValue = useContext(GroupContext);
    const [noticeFixedList, setFixedNoticeList] = useState<Array<INotice>>([]); 
    const [noticeNonFixedList, setNonFixedNoticeList] = useState<Array<INotice>>([]); 
    const jiguId = history.location.state.jigu;
    const authLevel = history.location.state.authLevel;
    const moveToNoticeDetail = (idx:number) => {
        console.log(" idx >> ", idx);
        history.push('/noticeDetail/'+idx , {id:idx, authLevel:authLevel});
    }

    useEffect(() => {
        console.log(" noticeNonFixedList > ", noticeNonFixedList);
    },[noticeNonFixedList])
   
    useEffect(() => {
        let fixedNotices: Array<INotice> = [];
        let nonFixedNotices: Array<INotice> = [];
        
        axios.get(comm.SERVER_URL+"/notice/getNoticeListJigu/"+jiguId).then(res => {
           res.data.map((eachNotice : INotice) => {
            if(eachNotice.fixedLevel == 1){
                fixedNotices.push(eachNotice);
            }else if(eachNotice.fixedLevel == 0){
                nonFixedNotices.push(eachNotice);
            }
           })
           setFixedNoticeList(fixedNotices);
           setNonFixedNoticeList(nonFixedNotices);

        });
    },[]);

    const getNotice = (eachNotice : INotice) => {
        console.log("eachNotice > ", eachNotice);
        return (<div key={eachNotice.id} className="flex flex-col rounded-md w-180 mt-2 border p-2 border-gray-400" onClick={(ev) =>{moveToNoticeDetail(eachNotice.id)}}>
            <div className='flex flex-row justify-between'>
                <span className='text-lg ml-2'>{eachNotice.title}</span>
                <span className='text-sm mr-2'>{eachNotice.uploadTime}</span>
            </div>
            <div className='h-6 ml-2'>{eachNotice.content.length < 54 ? eachNotice.content : eachNotice.content.substring(0,54)}</div>
        </div>)
    }

    return (<div className='grid place-items-center'>
        <div className='text-2xl mt-10 mb-2 font-bold'>지구 본부 공지</div>
        <div className='flex flex-col items-start'>
            <div className='text-lg font-semibold'>* 상단 고정 공지</div>
            <div className='flex flex-col mt-2 h-72 w-188 bg-blue-100 rounded-lg p-3 overflow-y-auto'>

                {
                    noticeFixedList.map((eachNotice: INotice) => {
                        return getNotice(eachNotice)
                    })
                }
            </div>
            <div className='mt-4 text-lg font-semibold'>*일반 공지</div>
            <div className='flex flex-col mt-2 mb-8 h-184 w-188 bg-gray-100 rounded-lg p-3 overflow-y-auto'>
                {
                    noticeNonFixedList.map((eachNotice: INotice) => {
                        return getNotice(eachNotice)
                    })
                }
            </div>
        </div>
    </div>)
}

export default NoticeListJigu;
