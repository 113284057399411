import React, { useState, useRef, useEffect } from 'react';
import logo from '../../images/lions_logo.png';
import axios from 'axios';
import comm from '../../common';
import { useHistory, useParams } from 'react-router-dom';
import homeImg from '../../images/home_back.png';
import { SmsDto } from '../common/dto';
import { BasicBlueButton } from '../common/commonUI';
import { ISmsInfo, ISurveyItem } from '../mobile/survey';
import { userDto } from '../mobile/memberList';

export interface ISurveyBoard{
    originalSms:ISmsInfo,
    items: Array<ISurveyItem>,
}

function SurveyBoard(props: any): any {
    const history: any = useHistory();
    const jiguId = history.location.state.jigu;
    const [surveyList, setSurveyList] = useState<Array<ISurveyBoard>>([]);
    const [clickedIndex, setClickedIndex] = useState<number>(-1);
    const [noReponseUserList, setNoReponseUserList] = useState<Array<userDto>>([]);
    const [responsedUserList, setReponseUserList] = useState<Array<userDto>>([]);
    
    useEffect(()=>{
        axios.get(comm.SERVER_URL+"/sms/getSurveyBoard/"+jiguId).then(res => {
            setSurveyList(res.data);
        });


    },[]);

    const getTotalRepliedUsersCnt = (survey: ISurveyBoard) => {
        let totalUsers = 0;
        survey.items.forEach(each => {
            totalUsers = totalUsers + each.replyUsers.length;
        });
        return totalUsers;
    }

    const getAllRepliedUserIds = (survey: ISurveyBoard) => {
        let usersIds : number[] = [];
        survey.items.forEach(each => {
            each.replyUsers.forEach(eachUser => {
                usersIds.push(eachUser);
            });
        });
        console.log("repliedUsers >> ", usersIds);
        return usersIds;
    }

    const getEachItemsRatio = (survey: ISurveyBoard, repliedUsersCnt:number ) => {
        let totalUsers = getTotalRepliedUsersCnt(survey);
        let ratio = Math.floor((repliedUsersCnt / totalUsers) * 100); 
        return ratio;
    }

    const getTotalSentUsers = async (survey : ISurveyBoard) => {
        let smsId = survey.originalSms.id;
        let result = await axios.get(comm.SERVER_URL+"/sms/sentUsers/"+smsId);
        console.log(" sentUsers >> ", result);
        return result;
    }

    const noResponseUsers = async (survey : ISurveyBoard, index:number) => {
        let totalSentUsers = await getTotalSentUsers(survey);
        let repliedUsers = getAllRepliedUserIds(survey);
        let filteredOut = totalSentUsers.data.filter((each:any) => {
            return !repliedUsers.includes(each.id) 
        });

        let filteredIn = totalSentUsers.data.filter((each:any) => {
            return repliedUsers.includes(each.id) 
        });

        // console.log("filtered !! >> ", filtered);
        setClickedIndex(index)
        setNoReponseUserList(filteredOut);
        setReponseUserList(filteredIn);
        // return filtered;
    }

    // const responsedUsers = async (survey : ISurveyBoard, index:number) => {
    //     let totalSentUsers = await getTotalSentUsers(survey);
    //     console.log(" totalSentUsers >> ", totalSentUsers);
    //     let filtered : Array<any> = [];
    //     return filtered;
    // }

    const moveToSmsTotalWithNoResponseUsers = () => {
        history.push('/sendSMS', {usersToSend: noReponseUserList});
    }

    const moveToSmsTotalWithResponsedUsers = () => {
        history.push('/sendSMS', {usersToSend: responsedUserList});
    }

    return (
        <div className="grid place-items-center mb-8">
            <div className='flex flex-col items-center'>
                <div className='flex flex-row items-center mt-10 mb-5'>
                    <span className='text-2xl font-bold'>설문/응답 통계</span>
                </div>
                {
                    surveyList.map((eachSurvey : ISurveyBoard, index:number) => {
                        // let totalSentUsers = getTotalSentUsers(eachSurvey);
                        return (<div className='flex flex-col border-2 mt-2 border-gray-400 rounded-lg p-3 w-144'>
                            <div className='flex flex-row items-center mb-2 justify-between'>
                                <div className='flex flex-row items-center'>
                                    <span className='text-lg font-semibold'>{eachSurvey.originalSms.content}</span>
                                    <button type="button" className="flex items-center justify-center rounded-lg border-red-600 border-solid border-2 border-opacity-60 w-16 h-6 ml-3 hover:bg-red-200"
                                        onClick={(ev) => {noResponseUsers(eachSurvey, index)}}><span className='text-sm text-red-400'>응답현황</span></button>
                                </div>

                                <span className='ml-6 text-sm'>{eachSurvey.originalSms.updatedAt.substr(0, 10).replace(/-/gi, '.')}</span>
                            </div>
                        
                            {
                                eachSurvey.items.map(eachItem => {
                                    return (<div className='flex flex-row w-144'>
                                        <span className='w-2/3'>{eachItem.itemContent} : </span>
                                        <span className='w-18 ml-2'>{eachItem.replyUsers.length} 명</span>
                                        <span className='ml-2'>{getEachItemsRatio(eachSurvey, eachItem.replyUsers.length)}%</span>
                                    </div>)
                                })
                            }
                                                        {   
                            clickedIndex == index ? 
                            responsedUserList.map((eachItem : any, idx:number) => {
                                    return (<div className='flex flex-col mt-4'>
                                        <div className={idx == 0 ? 'flex flex-row' :'hidden'}><span className='text-blue-500 mr-4'>응답자</span><BasicBlueButton btnText='문자보내기' width='w-24' height='h-6' font='text-sm' handleClick={moveToSmsTotalWithResponsedUsers} /></div>
                                        <div className='flex flex-row items-center ml-2 mt-2'>
                                            <span className='text-base'>{eachItem.name}</span>
                                            <span className='text-sm ml-4 text-gray-600'>{eachItem.clubName}</span>
                                            <span className='ml-4'>{eachItem.mobileNum}</span>
                                            <span className='ml-4'>{eachItem.surveyResponses.join(', ')}</span>
                                        </div>
                                    </div>)
                                }) : null
                            }
                            {   
                            clickedIndex == index ? 
                                noReponseUserList.map((eachItem : userDto, idx:number) => {
                                    return (<div className='flex flex-col mt-4'>
                                        <div className={idx == 0 ? 'flex flex-row' :'hidden'}><span className='text-red-500 mr-4'>미응답자</span><BasicBlueButton btnText='문자보내기' width='w-24' height='h-6' font='text-sm' handleClick={moveToSmsTotalWithNoResponseUsers} /></div>
                                        <div className='flex flex-row items-center ml-2 mt-2'>
                                            <span className='text-base'>{eachItem.name}</span>
                                            <span className='text-sm ml-4 text-gray-600'>{eachItem.clubName}</span>
                                            <span className='ml-4'>{eachItem.mobileNum}</span>
                                        </div>
                                    </div>)
                                }) : null
                            }
                        
                        </div>)
                    })
                }
            </div>
        </div>
    )
}  

export default SurveyBoard;