import React, { useState, useEffect, useContext } from 'react';
import comm from '../../common';
import axios from 'axios';
// import { Button, Hidden, IconButton, Snackbar } from '@material-ui/core';
// import Select from "react-select";
// import { autoSendDays, clubOption, jiguOption, jiyeokOption } from './selectItems';
// import { userDto } from '../mobile/memberList';
// import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
// import { Receiver, SmsDto } from '../common/dto';
import { useHistory } from 'react-router-dom';
import GroupContext from '../../context/groupContext';

export interface IReceiver{
    name:string,
    mobileNum:string,
    position:string,
  }
  
  export interface ISmsHistory{
    sender:string,
    content: string,
    receivers: Array<IReceiver>,
    sendingTime:string, 
    receiverGrop:Array<string>,
  }
  

const SmsHistory = () => {
    const history: any = useHistory();
    const [smsHistoryArr, setSmsHistoryArr] = useState<Array<ISmsHistory>>();
    const groupValue = useContext(GroupContext);
    console.log("groupValue >> ", groupValue);

    useEffect(() => {
        axios.get(comm.SERVER_URL+"/sms/sendHistory/"+groupValue.jiguId).then(res =>{
            // console.log(" >>>>>>>> ", res.data);
            setSmsHistoryArr(res.data.results);
        });
    }, []);

    const goToDetail = (ev :any, id:number) => {
        history.push('/smsHistoryDetial',{id:id})
    }

    const getFormattedTime = (time:any) => {
        let date = new Date(time);
        const year = date.getFullYear().toString().slice(2); // 연도를 2자리로
        const month = String(date.getMonth() + 1).padStart(2, '0'); // 월을 2자리로 맞춤
        const day = String(date.getDate()).padStart(2, '0'); // 일을 2자리로 맞춤
        const hours = String(date.getHours()).padStart(2, '0'); // 시간을 2자리로 맞춤
        const minutes = String(date.getMinutes()).padStart(2, '0'); // 분을 2자리로 맞춤
        const formattedDate = `${year}.${month}.${day} ${hours}:${minutes}`;
        return formattedDate;
    }

    return (<div className='grid place-items-center '>
        <div className='mt-10 text-2xl font-bold mb-4'>전송 기록</div>
        <div className='flex flex-col items-center'>
        {
            smsHistoryArr?.map(eachHistory => {
                let nicks = "";
                eachHistory.receivers.forEach((nick, index) => {
                    if(index == eachHistory.receivers.length-1){
                        nicks = nicks + nick.name;
                    }else{
                        nicks = nicks + nick.name +", ";
                    }
                })
                  
                return (<div className='flex flex-col m-3 border-2 border-gray-400 bg-blue-50 rounded-md w-144 p-3' >
                    <div className='flex flex-row'>
                        <span className='w-24'>전송 시간: </span>
                        <span className=''>{getFormattedTime(eachHistory.sendingTime)}</span>
                    </div>
                    <div className='flex flex-row'>
                        <span className='w-24'>보내는 사람: </span>
                        <span className=''>{eachHistory.sender}</span>
                    </div>
                    <div className='flex flex-row'>
                        <span className='w-24'>받는 사람: </span>
                        <span className=''>{nicks.includes("undefined") ? "" : nicks}</span>
                    </div>
           
                    <span className='mt-2'>[내용]</span>
                    <div className=''>
                        {eachHistory.content}
                    </div>
                </div>)
            })
        }
        </div>

    </div>)
}

export default SmsHistory;

