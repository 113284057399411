export const clubOption = [
    { value: 0, label: "클럽 직책을 선택해 주세요" },
    { value: 1, label: "회장" },
    { value: 2, label: "직전회장" },
    { value: 3, label: "제1부회장" },
    { value: 4, label: "제2부회장" },
    { value: 5, label: "제3부회장" },
    { value: 6, label: "총무" },
    { value: 7, label: "재무" },
    { value: 8, label: "테일트위스타" },
    { value: 9, label: "라이온테마" },
    { value: 10, label: "회원증강위원장" },
    { value: 11, label: "회원위원" },
    { value: 12, label: "감사" },
    { value: 13, label: "마케팅커뮤니케이션위원장" },
    { value: 14, label: "GST(클럽봉사위원장)" },

    { value: 15, label: "GMT위원장" },
    { value: 16, label: "LCIF코디네이터" },
    { value: 17, label: "안전임원" },

    { value: 121, label: "이사" },
    { value: 122, label: "회원" },
    { value: 123, label: "사무장" },
]

export const clubOption356E = [
    { value: 0, label: "클럽 직책을 선택해 주세요" },
    { value: 1, label: "회장" },
    { value: 6, label: "총무" },
    { value: 7, label: "재무" },
    { value: 122, label: "회원" },
]

export const jiguOption = [
    { value: 0, label: "지구 직책을 선택해 주세요" },
    { value: 1, label: "총재" },
    { value: 3, label: "총재협의회장" },
    { value: 5, label: "직전총재" },
    
    { value: 9, label: "지구 제1부총재" },
    { value: 12, label: "지구 제2부총재" },
    { value: 13, label: "전의장" },
    { value: 15, label: "전 총재" },
    { value: 18, label: "감사" },
    { value: 21, label: "사무총장" },
    { value: 22, label: "재무총장" },
    { value: 23, label: "기획총장" },  //추가 24.07.07
    { value: 24, label: "의전총장" },  //추가 24.07.07

    { value: 25, label: "총재특보" },   // 추가 23.07.04

    { value: 16, label: "총재협의회" }, // 추가 23.08.07
    { value: 17, label: "총장협의회" }, // 추가 23.08.07
    { value: 27, label: "GLT 코디네이터" },
    { value: 30, label: "GMT 코디네이터" },
    { value: 33, label: "GST 코디네이터" },
    { value: 36, label: "LCIF 코디네이터" },
    { value: 39, label: "상임고문" },
    { value: 42, label: "고문" },
    { value: 44, label: "총재특별자문위원" }, //추가 24.07.07
    { value: 45, label: "자문위원" },
    { value: 51, label: "수석 부총장"},
    { value: 52, label: "사무 부총장"},    //추가 24.07.07
    { value: 54, label: "부총장"},
    { value: 57, label: "분과위원장"},
    { value: 60, label: "연수원 원장"},
    { value: 61, label: "연수원 부원장"},  // 추가 23.07.04
    { value: 63, label: "연수원 교수부장"},
    { value: 64, label: "연수원 교수처장"},
    { value: 66, label: "연수원 교수"},
    { value: 67, label: "연수원 교수위원"},
    { value: 68, label: "연수원 교육위원"},
    { value: 70, label: "인사위원회"},  // 추가 23.08.25
    { value: 75, label: "직능부총재"},
    { value: 79, label: "특별위원장"},
    // {value:100, label:"역대 지구 총재"},
]

export const jiyeokOption = [
    { value: 0, label: "지역 직책을 선택해 주세요" },
    { value: 1, label: "지역위원장" },
    { value: 2, label: "직전 지역위원장" },
    { value: 3, label: "지대 위원장" },
    { value: 4, label: "지역 감사" },
    { value: 5, label: "지역 사무총장" },
    { value: 6, label: "지역 총무" },
    { value: 7, label: "지역 부총무" },
    { value: 8, label: "지역 재무" },
    { value: 12, label: "지역특보" }, //추가 24.07.07 
]

// 클럽회장, 여성지구임원, 총무, 재무, 전총재, 감사, 고문, 자문위원, 지구임원, 1부회장, 지대위원장, 지역부총재, 1부회장


export const formerJiguChongjaeOption = [
    { value: 119, label: "역대 지구 총재(기수 불명)" },
    { value: 31, label: "1대 지구 총재" },
    { value: 32, label: "2대 지구 총재" },
    { value: 33, label: "3대 지구 총재" },
    { value: 34, label: "4대 지구 총재" },
    { value: 35, label: "5대 지구 총재" },
    { value: 36, label: "6대 지구 총재" },
    { value: 37, label: "7대 지구 총재" },
    { value: 38, label: "8대 지구 총재" },
    { value: 39, label: "9대 지구 총재" },
    { value: 40, label: "10대 지구 총재" },
    { value: 41, label: "11대 지구 총재" },
    { value: 42, label: "12대 지구 총재" },
    { value: 43, label: "13대 지구 총재" },
    { value: 44, label: "14대 지구 총재" },
    { value: 45, label: "15대 지구 총재" },
    { value: 46, label: "16대 지구 총재" },
    { value: 47, label: "17대 지구 총재" },
    { value: 48, label: "18대 지구 총재" },
    { value: 49, label: "19대 지구 총재" },
    { value: 50, label: "20대 지구 총재" },
    { value: 51, label: "21대 지구 총재" },
    { value: 52, label: "22대 지구 총재" },
    { value: 53, label: "23대 지구 총재" },
    { value: 54, label: "24대 지구 총재" },
    { value: 55, label: "25대 지구 총재" },
    { value: 56, label: "26대 지구 총재" },
    { value: 57, label: "27대 지구 총재" },
    { value: 58, label: "28대 지구 총재" },
    { value: 59, label: "29대 지구 총재" },
    { value: 60, label: "30대 지구 총재" },
    { value: 61, label: "31대 지구 총재" },
    { value: 62, label: "32대 지구 총재" },
    { value: 63, label: "33대 지구 총재" },
    { value: 64, label: "34대 지구 총재" },
    { value: 65, label: "35대 지구 총재" },
    { value: 66, label: "36대 지구 총재" },
    { value: 67, label: "37대 지구 총재" },
    { value: 68, label: "38대 지구 총재" },
    { value: 69, label: "39대 지구 총재" },
    { value: 70, label: "40대 지구 총재" },
    { value: 71, label: "41대 지구 총재" },
    { value: 72, label: "42대 지구 총재" },
    { value: 73, label: "43대 지구 총재" },
    { value: 74, label: "44대 지구 총재" },
    { value: 75, label: "45대 지구 총재" },
    { value: 76, label: "46대 지구 총재" },
    { value: 77, label: "47대 지구 총재" },
    { value: 78, label: "48대 지구 총재" },
    { value: 79, label: "49대 지구 총재" },
    { value: 80, label: "50대 지구 총재" },
    { value: 81, label: "51대 지구 총재" },
    { value: 82, label: "52대 지구 총재" },
    { value: 83, label: "53대 지구 총재" },
    { value: 84, label: "54대 지구 총재" },
    { value: 85, label: "55대 지구 총재" },
    { value: 86, label: "56대 지구 총재" },
    { value: 87, label: "57대 지구 총재" },
    { value: 88, label: "58대 지구 총재" },
    { value: 89, label: "59대 지구 총재" },
    { value: 90, label: "60대 지구 총재" },
    { value: 91, label: "61대 지구 총재" },
    { value: 92, label: "62대 지구 총재" },
    { value: 93, label: "63대 지구 총재" },
    { value: 94, label: "64대 지구 총재" },
    { value: 95, label: "65대 지구 총재" },
    { value: 96, label: "66대 지구 총재" },
    { value: 97, label: "67대 지구 총재" },
    { value: 98, label: "68대 지구 총재" },
    { value: 99, label: "69대 지구 총재" },
    { value: 100, label: "70대 지구 총재" },
]

export  const memberOptionTypes = [
    {value:0, label:"회원 타입을 선택해 주세요"},
    {value:1, label:"일반 회원"},
    {value:2, label:"가족 회원"},
    {value:3, label:"재입 회원"},
    {value:4, label:"전입 회원"},
    {value:5, label:"퇴회 회원"},
]

export  const volunteerTypes = [
    {value:0, label:"봉사타입 선택"},
    {value:1, label:"불우이웃 지원"},
    {value:2, label:"무료진료 사업"},
    {value:3, label:"사회공익 사업"},
    {value:4, label:"장학 사업"},
    {value:5, label:"재해 지원"},
    {value:6, label:"환경보존 사업"},
    {value:7, label:"기타"},
]

export  const quitReason = [
    {value:1, label:"굿스탠딩퇴회"},
    {value:2, label:"회비미납"},
    {value:3, label:"장기결석"},
    {value:4, label:"주거이전"},
    {value:5, label:"사망"},
    {value:6, label:"전출"},
    {value:7, label:"기타"},
]

export  const clubGenderTypes = [
    {value:0, label:"클럽타입 선택"},
    {value:1, label:"남성클럽"},
    {value:2, label:"여성클럽"},
    {value:3, label:"혼성클럽"},
]


export  const months = [
    {value:0, label:"달 선택"},
    {value:1, label:"1월"},
    {value:2, label:"2월"},
    {value:3, label:"3월"},
    {value:4, label:"4월"},
    {value:5, label:"5월"},
    {value:6, label:"6월"},
    {value:7, label:"7월"},
    {value:8, label:"8월"},
    {value:9, label:"9월"},
    {value:10, label:"10월"},
    {value:11, label:"11월"},
    {value:12, label:"12월"},
]

export const gender =[
    {value:0, label:"성별 선택"},
    {value:1, label:"남자"},
    {value:2, label:"여자"},
]

export const autoSendDays=[
    {value:0, label:"발송 주기 선택"},
    {value:1, label:"1일"},
    {value:2, label:"2일"},    
    {value:3, label:"3일"},
    {value:5, label:"5일"},
    {value:7, label:"7일"},
    {value:15, label:"15일"},
    {value:30, label:"30일"},
]

export const terms = [
    {value:0, label:"회기 선택"},
    {value:1, label:"1기"},
    {value:2, label:"2기"},    
    {value:3, label:"3기"},
    {value:4, label:"5기"},
    {value:5, label:"5기"},
    {value:5, label:"6기"},
    {value:7, label:"7기"},
];

export const formerChairmanOption = [
    { value: 119, label: "역대 회장(기수 불명)" },
    { value: 31, label: "1대 회장" },
    { value: 32, label: "2대 회장" },
    { value: 33, label: "3대 회장" },
    { value: 34, label: "4대 회장" },
    { value: 35, label: "5대 회장" },
    { value: 36, label: "6대 회장" },
    { value: 37, label: "7대 회장" },
    { value: 38, label: "8대 회장" },
    { value: 39, label: "9대 회장" },
    { value: 40, label: "10대 회장" },
    { value: 41, label: "11대 회장" },
    { value: 42, label: "12대 회장" },
    { value: 43, label: "13대 회장" },
    { value: 44, label: "14대 회장" },
    { value: 45, label: "15대 회장" },
    { value: 46, label: "16대 회장" },
    { value: 47, label: "17대 회장" },
    { value: 48, label: "18대 회장" },
    { value: 49, label: "19대 회장" },
    { value: 50, label: "20대 회장" },
    { value: 51, label: "21대 회장" },
    { value: 52, label: "22대 회장" },
    { value: 53, label: "23대 회장" },
    { value: 54, label: "24대 회장" },
    { value: 55, label: "25대 회장" },
    { value: 56, label: "26대 회장" },
    { value: 57, label: "27대 회장" },
    { value: 58, label: "28대 회장" },
    { value: 59, label: "29대 회장" },
    { value: 60, label: "30대 회장" },
    { value: 61, label: "31대 회장" },
    { value: 62, label: "32대 회장" },
    { value: 63, label: "33대 회장" },
    { value: 64, label: "34대 회장" },
    { value: 65, label: "35대 회장" },
    { value: 66, label: "36대 회장" },
    { value: 67, label: "37대 회장" },
    { value: 68, label: "38대 회장" },
    { value: 69, label: "39대 회장" },
    { value: 70, label: "40대 회장" },
    { value: 71, label: "41대 회장" },
    { value: 72, label: "42대 회장" },
    { value: 73, label: "43대 회장" },
    { value: 74, label: "44대 회장" },
    { value: 75, label: "45대 회장" },
    { value: 76, label: "46대 회장" },
    { value: 77, label: "47대 회장" },
    { value: 78, label: "48대 회장" },
    { value: 79, label: "49대 회장" },
    { value: 80, label: "50대 회장" },
    { value: 81, label: "51대 회장" },
    { value: 82, label: "52대 회장" },
    { value: 83, label: "53대 회장" },
    { value: 84, label: "54대 회장" },
    { value: 85, label: "55대 회장" },
    { value: 86, label: "56대 회장" },
    { value: 87, label: "57대 회장" },
    { value: 88, label: "58대 회장" },
    { value: 89, label: "59대 회장" },
    { value: 90, label: "60대 회장" },
    { value: 91, label: "61대 회장" },
    { value: 92, label: "62대 회장" },
    { value: 93, label: "63대 회장" },
    { value: 94, label: "64대 회장" },
    { value: 95, label: "65대 회장" },
    { value: 96, label: "66대 회장" },
    { value: 97, label: "67대 회장" },
    { value: 98, label: "68대 회장" },
    { value: 99, label: "69대 회장" },
    { value: 100, label: "70대 회장" },
]

