import React, { useState, useRef, useEffect } from 'react';
import logo from '../../images/lions_logo.png';
import axios from 'axios';
import comm from '../../common';
import { useHistory, useParams } from 'react-router-dom';
import homeImg from '../../images/home_back.png';
import { SmsDto } from '../common/dto';
import { BasicBlueButton } from '../common/commonUI';

// var encodeUrl = require('encodeurl')
export interface ISurveyItem {
    id:number,
    smsId:number,
    itemContent: string,
    replyUsers:Array<number>,
}

export interface ISurveyResponse {
    surveyItemId:number,
    repliedUserId:number,
}

export interface ISmsInfo {
    selectionType:number,
    id:number,
    content:string,
    updatedAt:string,
}

function Survey(props: any): any {
    const history: any = useHistory();
    const {smsId, userId} = useParams<any>();
    const [smsInfo, setSmsInfo] = useState<ISmsInfo>({selectionType:0, id:0, content:'', updatedAt:''});
    const [surveyResponse, setSurveyResponse] = useState<Array<ISurveyResponse>>([]);
    const [surveyItems, setSurveyItems] = useState<Array<ISurveyItem>>([]);

    useEffect(()=>{
        axios.get(comm.SERVER_URL+"/sms/byId/"+smsId).then(res => {
            setSmsInfo(res.data);
        });
        axios.get(comm.SERVER_URL+"/sms/getSurveyItems/"+smsId).then(res => {
            setSurveyItems(res.data);
        });

    },[]);

    useEffect(()=>{
        console.log(" smsInfo >> ", smsInfo);
    },[smsInfo])

    const checkMultipleResponse = (ev:any, each:ISurveyItem) => {
        if(ev.target.checked){
            setSurveyResponse([...surveyResponse, {surveyItemId:each.id, repliedUserId:userId}]);
        }else{
            setSurveyResponse(surveyResponse.filter(eachSurvey => {
                return each.id != eachSurvey.surveyItemId;
            }));
        }
    };

    const checkSingleResponse = (each:ISurveyItem) => {
        setSurveyResponse([{surveyItemId:each.id, repliedUserId:userId}]);
    };

    const getMultipleSelectItemsUX = () => {
        return surveyItems.map((eachItem : ISurveyItem)=>{
            return <label className="flex items-center mt-2">
            <input type="checkbox" name="surveyCheck" value={eachItem.id} className="w-6 h-6 mr-2 " onChange={(ev) => {checkMultipleResponse(ev, eachItem)}}/>{eachItem.itemContent}
        </label>
        })
    };

    const getSingleSelectItemUX = () => {
        return surveyItems.map((eachItem : ISurveyItem)=>{
            return <label className="flex items-center mt-2">
            <input type="radio" name="surveyRadio" value={eachItem.id} className="w-6 h-6 mr-2 " onChange={(ev) => {checkSingleResponse(eachItem)}}/>{eachItem.itemContent}
        </label>
        })
    };

    const submitSurvey = async () => {
        console.log(" ##### ", userId);
        let result, checkAlreadyReplied;
        
        // 전체 survey 에서 해당 user가 응답한게 있는지 검사하고 있으면 응답을 삭제한다. (무조건 응답 새로 수정)  
        checkAlreadyReplied = await axios.get(comm.SERVER_URL+"/sms/geAlreadyReplied/"+smsId+"/"+userId);
        console.log("checkAlreadyReplied >> ", checkAlreadyReplied);

        for(let i=0; i<surveyResponse.length; i++){
            result = await axios.get(comm.SERVER_URL+"/sms/surveyItems/"+surveyResponse[i].surveyItemId+"/"+surveyResponse[i].repliedUserId);
            console.log("result >> ", result);
        }
 
        if(result && result.data.ok){
            alert("설문(투표)이 완료되었습니다.");
            history.push("/home")

        }
        
    }

    return (
        <div className="grid place-items-center">
          <div className='flex flex-col mt-6 ml-4'>
            <div className='mb-6 mt-4 text-xl font-semibold'>{smsInfo.content}</div>
            <>
            {
                smsInfo.selectionType == 0 ? null : smsInfo.selectionType == 1 ? getSingleSelectItemUX() : getMultipleSelectItemsUX()
            }
            </>
            <div className='h-12'></div>
            <BasicBlueButton width='w-60' height='h-10' btnText='설문(투표) 제출' font='text-basic' handleClick={submitSurvey} /> 
          </div>
        </div>
    )
}

export default Survey;